import api from '../../services/ApiService'
import store from '../store';

const state = {
    makers: []
};

const getters = {
    makers() {
        return state.makers
    }
};

const actions = {
    fetchMakers({commit}) {
        store.dispatch('loading/setLoading', true);
        return api.getMakers().then((makers => {
            commit('setMakers', makers);
            store.dispatch('loading/setLoading', false);
        }));
    },
    fetchMaker({commit}, makerLabel) {
        store.dispatch('loading/setLoading', true);
        return api.getMaker(makerLabel).then((maker => {
            commit('setMakers', [maker]);
            store.dispatch('loading/setLoading', false);
        }));
    }
};

const mutations = {
    setMakers(state, makers) {
        state.makers = makers;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};