export default {
    getCo2(engine) {
        let td = engine.technical_data;

        if(td.co2_level_combined) {
            return td.co2_level_combined;
        }
        
        if(td.wltp_co2_level_combined) {
            return td.wltp_co2_level_combined;
        }

        if(td.wltp_co2_level_combined_high) {
            return td.wltp_co2_level_combined_high;
        }

        return "-"
    },
    getConsumption(engine) {
        let td = engine.technical_data;

        if(td.fuel_consumption_combined) {
            return td.fuel_consumption_combined
        }

        if(td.wltp_fuel_consumption_combined) {
            return td.wltp_fuel_consumption_combined;
        }

        if(td.wltp_co2_level_combined_high) {
            return td.wltp_co2_level_combined_high;
        }

        return "-";
    },
    getPowerConsumption(engine) {
        let td = engine.technical_data;
        if(td.power_consumption_combined) {
            return td.power_consumption_combined;
        }

        if(td.power_consumption_combined_per_km) {
            return td.power_consumption_combined_per_km/10;
        }

        if(td.wltp_power_consumption_combined_per_km_high) {
            return td.wltp_power_consumption_combined_per_km_high/10;
        }

        return "-";
    },
    getGasConsumption(engine) {
        let td = engine.technical_data;
        if(td.gas_consumption_combined) {
            return td.gas_consumption_combined;
        }

        if(td.wltp_gas_consumption_combined) {
            return td.wltp_gas_consumption_combined;
        }

        if(td.wltp_gas_consumption_combined_high) {
            return td.wltp_gas_consumption_combined_high;            
        }

        return "-";
    }
}