<template>
  <b-container fluid>
    <b-row >
     <b-col cols="*"
        v-bind:key="trimLevel.id"
        v-for="trimLevel in extendedTrimLevels"
        class="p-2 d-flex"
      >
          <b-card
            :img-src="getCarImage(trimLevel)"
            v-bind:img-alt="trimLevel.label"
            @click.prevent="selectTrimLevel(trimLevel)"
            v-bind:title="trimLevel.label"
          >
            <b-card-text>
              <div>
                <div>
                  <ul>
                    <li v-for="highlight in trimLevel.equipmentHighlights"
                      v-bind:key="highlight"
                    >{{ highlight }}</li>
                  </ul>
                  <p>
                    <b-link @click.stop="showEquipment(trimLevel)">mehr erfahren</b-link>
                  </p>
                </div>
                <div>
                  <p class="h3" pill>ab {{ getNewPrice(trimLevel['min_price_'+pricing], trimLevel) | formatCurrency }}&nbsp;€</p>
                  <p class="h6" style="text-decoration: line-through">uvp ab {{ trimLevel.min_price_gross | formatCurrency }}&nbsp;€</p>
                </div>
              </div>
            </b-card-text>

            <template #footer >
              <div>
                <b-button block variant="primary">jetzt konfigurieren</b-button>
            </div>
            </template>
          </b-card>
      </b-col>
    </b-row>

    <!--  # # # MODAL Ausstattungsvergleich # # #  -->
  
    <b-modal
      id="modal-equipment-compairsion"
      title="Ausstattungsvergleich"
      size="xl"
    >

<!-- START Image AND Model -->

      <template #default="{}">
        <b-container>
          <b-row>
            <b-col cols="12" md="6">
              <CarImage :imageUrl="getCarImage(currentModel)" />
            </b-col>
            <b-col>
              <h4>{{currentMaker.label}}</h4>
              <h6>{{currentModel.label}}</h6>
              <h6>{{currentBodyDesign.label}}</h6>
            </b-col>
          </b-row>
        </b-container>

<!-- END Image AND Model -->

      <b-container>
        <b-row>
          <b-col cols="12">
            <p>
              Bitte wählen Sie 2 verschiedene Ausstattungslinien.
            </p>
          </b-col>
          <b-col cols="12" md="6" >
            <v-select
              label="label"
              placeholder="Modell 1"
              :options="extendedTrimLevels"
              v-model="firstSelectedTrimLevel"
            ></v-select>
          </b-col>
          <b-col cols="12" md="6" >
            <v-select
              label="label"
              placeholder="Modell 2"
              :options="extendedTrimLevels"
              v-model="secondSelectedTrimLevel"
            ></v-select>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="difference-only"
              v-model="showDifference"
              name="difference-only"
              switch
              >nur Unterschiede anzeigen
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <div>          
          <b-table-simple table-fit hover small caption-top responsive no-body 
              v-for="(category, categoryIndex) in equipmentCompairsion"
              v-bind:key="category.category">
            <b-thead head-variant="light">
              <b-tr>
                <b-th style="width: auto" v-b-toggle="'equipment-category-' + categoryIndex">{{category.category}}</b-th>
                <b-th class="icon-row">{{firstSelectedTrimLevel.label}}</b-th>
                <b-th class="icon-row">{{secondSelectedTrimLevel.label}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(equipment, equipmentIndex) in category.equipmentList"
                v-bind:key="equipmentIndex">
                <b-td variant="">{{equipment.title}}</b-td>
                <b-td><i class="check-icon" v-if="equipment.cars[0]"/></b-td>
                <b-td><i class="check-icon" v-if="equipment.cars[1]"/></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-container>

      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()"
          >Schliessen</b-button
        >
      </template>
    </b-modal>


<!--  # # # MODAL mehr erfahren // Details # # #  -->
    
    <b-modal
      id="equipment-modal"
      size="xl"
      v-bind:title="currentMaker.label"
      >
      
<!-- START Image AND Model -->

      <template #default="{}">
        <b-container>
          <b-row>
            <b-col cols="12" md="6">
              <CarImage :imageUrl="getCarImage(currentModel)" />
            </b-col>
            <b-col>
              <h4>{{currentMaker.label}} {{selectedTrimLevel.label}}</h4>
              <h6>{{currentModel.label}}</h6>
              <h6>{{currentBodyDesign.label}}</h6>
            </b-col>
          </b-row>
        </b-container>

<!-- END Image AND Model -->

      <b-container>
        <div>          
          <b-table-simple table-fit hover small caption-top responsive no-body 
              v-for="(equipmentCategory, categoryIndex) in selectedTrimLevel.equipmentList"
              v-bind:key="equipmentCategory.category">
            <b-thead head-variant="light">
              <b-tr>
                <b-th style="width: auto" v-b-toggle="'equipment-category-' + categoryIndex"
                class="accordion-header-button"
                >{{equipmentCategory.category}}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(
                      equipmentLabel, equipmentIndex
                    ) in equipmentCategory.equipmentEntries"
                    v-bind:key="equipmentIndex"
                  >
                <b-td>{{equipmentLabel}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-container>
      
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()"
          >Schliessen</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../../services/ApiService";
import imageService from "../../services/ImageService";
import eventBus from "../../eventing/eventBus";
import CarImage from '../elements/CarImage.vue';
import ConditionsService from "../../services/ConditionsService";
import config from "../../config";

/* eslint-disable no-debugger */
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 4,
      title: "Ausstattungslinie",
      subline: "Wählen Sie eine Ausstattungslinie.",
    });
    this.$store.commit("setShowEquipmentCompairsionLink", true);
    this.$store.commit("setShowCarInfo", false);
  },
  mounted() {
    ConditionsService.initialize(this.conditions);

    let vm = this;

    eventBus.$on("open-equipment-compairsion", () => {
      vm.openEquipmentCompairsion();
    });

    if (!this.$props.bodyDesignId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return vm.fetchTrimLevels(bodyDesign.id).then(() => {
                const engine = this.getEngineFromTrimLevel(vm.trimLevels[0]);
                return api.getCompairsion(engine).then((compairsion) => {
                  vm.compairsion = compairsion;
                  vm.updateTrimLevels();
                });
              });
            });
        });
      });
    } else {
      const vm = this;

      return vm.fetchTrimLevels(this.$props.bodyDesignId).then(() => {
        const engine = this.getEngineFromTrimLevel(vm.trimLevels[0]);

        return api.getCompairsion(engine).then((compairsion) => {
          vm.compairsion = compairsion;
          vm.updateTrimLevels();
        });
      });
    }
  },

  methods: {
    ...mapActions({
      fetchTrimLevels: "trimLevels/fetchTrimLevels",      
    }),
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
    updateTrimLevels() {
      const vm = this;

      if (this.trimLevels) {
        this.trimLevels.forEach((t, i) => {
          let currentTrimLevel = t;
          currentTrimLevel.equipmentHighlights = vm.getEquipmentHighlights(i);
          currentTrimLevel.equipmentList = vm.getEquipment(i);
          vm.extendedTrimLevels.push(currentTrimLevel);
        });
      }
    },





    showEquipment(trimLevel) {
      this.selectedTrimLevel = trimLevel;
      this.$bvModal.show("equipment-modal");
    },
    getEquipmentHighlights(currentIndex) {
      let equipment = this.compairsion.equipment;
      let equipmentHighlights = [];
      let includedEquipment = [];

      //Differences
      for (let equipmentKey in equipment) {
        if (currentIndex == 0) {
          if (equipment[equipmentKey].cars[currentIndex]) {
            equipmentHighlights.push(equipmentKey);
          }
        } else {
          if (equipment[equipmentKey].cars[currentIndex]) {
            includedEquipment.push(equipmentKey);
            if (!equipment[equipmentKey].cars[currentIndex - 1]) {
              equipmentHighlights.push(equipmentKey);
            }
          }
        }

        if (equipmentHighlights.length == 4) {
          break;
        }
      }

      // Fillup if
      if (equipmentHighlights.length < 4) {
        for (let eq in includedEquipment) {
          if (!equipmentHighlights.includes(includedEquipment[eq])) {
            equipmentHighlights.push(includedEquipment[eq]);
          }

          if (equipmentHighlights.length === 4) {
            break;
          }
        }
      }
      return equipmentHighlights;
    },
    getEquipment(currentIndex) {
      let equipment = this.compairsion.equipment;
      let categories = [];

      //Differences
      for (let equipmentKey in equipment) {
        if (equipment[equipmentKey].cars[currentIndex]) {
          const categoryExists = categories.some((c) => {
            return c.category === equipment[equipmentKey].category_name;
          });

          if (!categoryExists) {
            categories.push({
              category: equipment[equipmentKey].category_name,
              equipmentEntries: [equipmentKey],
            });
          } else {
            categories.some((c) => {
              if (c.category === equipment[equipmentKey].category_name) {
                c.equipmentEntries.push(equipmentKey);
                return true;
              }
              return false;
            });
          }
        }
      }

      return categories;
    },
    getCarImage(trimLevel) {
      return imageService.buildImageUrl(trimLevel);
    },
    getEngineFromTrimLevel(trimLevel) {
      let objectKey = undefined;
      const engine = trimLevel.min_engine;

      if ("SSCD" in engine) {
        objectKey = "SSCD";
      }

      if ("NSCD" in engine) {
        objectKey = "NSCD";
      }

      return engine[objectKey].engine;
    },
    openEquipmentCompairsion() {
      this.$bvModal.show("modal-equipment-compairsion");
    },
    getIndexForTrimLevel(trimLevel) {
      let idx = -1;

      this.extendedTrimLevels.some((t, i) => {
        if (trimLevel.id === t.id) {
          idx = i;
          return true;
        }

        return false;
      });

      return idx;
    },
    processEquipmentCompairsion() {
      const vm = this;

      if (this.firstSelectedTrimLevel && this.secondSelectedTrimLevel) {
        const firstIndex = this.getIndexForTrimLevel(
          this.firstSelectedTrimLevel
        );
        const secondIndex = this.getIndexForTrimLevel(
          this.secondSelectedTrimLevel
        );
        let categories = [];

        this.compairsion.categories.forEach((cat) => {
          categories.push({ category: cat, equipmentList: [] });
        });

        for (let key in this.compairsion.equipment) {
          const equipment = this.compairsion.equipment[key];

          categories.some((cat, i) => {
            if (cat.category === equipment.category_name) {
              if (!vm.showDifference) {
                categories[i].equipmentList.push({
                  title: key,
                  cars: [
                    equipment.cars[firstIndex],
                    equipment.cars[secondIndex],
                  ],
                });
              } else {
                if (
                  equipment.cars[firstIndex] !== equipment.cars[secondIndex]
                ) {
                  categories[i].equipmentList.push({
                    title: key,
                    cars: [
                      equipment.cars[firstIndex],
                      equipment.cars[secondIndex],
                    ],
                  });
                }
              }
            }
          });
        }
        this.equipmentCompairsion = categories;
        return;
      }

      this.equipmentCompairsion = [];
    },
    selectTrimLevel(trimLevel) {
      this.$store.commit("carConfiguration/setCurrentTrimLevel", trimLevel);
      this.$store.commit("setShowEquipmentCompairsionLink", true);
      this.$router.push({
        name: "engines",
        params: {
          maker: this.currentMaker.label,
          model: this.currentModel.label,
          bodyDesign: this.currentBodyDesign.label,          
          trimLevel: trimLevel.label,
          trimLevelId: trimLevel.id
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      trimLevels: "trimLevels/trimLevels",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },
    equipmentTitle() {
      return this.selectedTrimLevel
        ? "Variante: " + this.selectedTrimLevel.label
        : "";
    },
  },
  components: {
    CarImage
  },
  data: function () {
    return {
      extendedTrimLevels: [],
      compairsion: {},
      showDifference: true,
      selectedTrimLevel: undefined,
      firstSelectedTrimLevel: undefined,
      secondSelectedTrimLevel: undefined,
      equipmentCompairsion: [],
    };
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
    firstSelectedTrimLevel: function () {
      this.processEquipmentCompairsion();
    },
    secondSelectedTrimLevel: function () {
      this.processEquipmentCompairsion();
    },
    showDifference: function () {
      this.processEquipmentCompairsion();
    },
  },
  props: ["bodyDesignId"],
};
</script>



<style>
.accordion .card {
  min-height: unset;
  max-width: 100%;
}


.cd-trim-level .card-text {
  padding: 15px;
  color: #757575;
}


.cd-model-image {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.cd-model-label,
.cd-trimlevel-label {
  color: #161616;
  font-weight: bold;
  font-size: 1rem;
}

.cd-original-price {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
  font-size: .9rem;
}

.cd-entry.cd-trim-level .cd-trim-level-price {
  padding: 0 15px;
  text-align: left;
}

.cd-entry.cd-trim-level .more-link {
  color: #496cf6;
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.cd-entry.cd-trim-level .more-link-container {
  text-align: left;
  margin-left: 15px;
}


.cd-entry.cd-trim-level .card {
  height: auto;
  position: relative;
  min-height: auto;
  background-color: #F9F9F9;
}

.cd-entry.cd-trim-level ul {
  height: 298px;
  overflow: hidden;
  margin-top: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.cd-entry.cd-trim-level .cd-trim-level-list li {
  font-size: .9rem;
  font-weight: normal;
}

.cd-entry.cd-model .card {
  overflow: hidden;
}

.cd-configure-hover {
  width: 100%;
  height: 55px;
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  bottom: -55px;
}

.cd-entry.cd-model .card:hover .cd-configure-hover {
  bottom: 0px;
}

.cd-equipment-summary-first {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 50px;
  margin-bottom: 0;
}

#cd-modal-equipment-compairsion .cd-equipment-summary-first {
  margin-top: 35px;
}

#cd-modal-equipment-compairsion .modal-body {
  padding-top: 0px;
}

.cd-equipment-summary-second {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

#cd-modal-equipment-compairsion h5 {
  color: #003899;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
}

.cd-compairsion-selection-hint {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.cd-equipment-row {
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
}

#cd-modal-equipment-compairsion .custom-checkbox label {
  font-size: .9rem;
}

/* NEW */

table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

table .icon-row {
  min-width: 10rem;
}

th:nth-child(2),
th:nth-child(3),
td:nth-child(2),
td:nth-child(3) {
  text-align: center;
}


</style>
