<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <div
      class="entry"
      v-for="extra in orderedExtras"
      v-bind:key="extra.id"
    >
      <label
        :for="'modal-' + extra.id"
        :class="hasAndOperator(node) && !hasSubTrees(node) ? 'disabled' : ''"
        >{{ extra.label }} ({{ extra.option_code }})</label>
      <input
        :id="'modal-' + extra.id"
        type="checkbox"
        disabled="true"
        v-model="extra.selected"
        class="purple-radio"
        v-if="hasAndOperator(node) && !hasSubTrees(node)"
        v-on:change="checkRequirements"
        v-default="extra"
      />
      <input
        :id="'modal-' + extra.id"
        type="radio"
        :name="level"
        :disabled="levelDisabled"
        v-model="selectedExtraId[currentLevel].id"
        :value="extra.id"
        v-if="hasOrOperator(node) && !hasSubTrees(node)"
        class="purple-radio"
        v-on:change="selectedExtraChanged(node)"
        
      />
      <span class="price">
        <span v-if="extra.hidden_costs != null && extra.hidden_costs.gross">{{
          extra.hidden_costs.gross | formatCurrency
        }} €</span>
        <span v-if="extra.hidden_costs == null && extra.price_gross">{{
          extra.price_gross | formatCurrency
        }}&nbsp;€</span>
      </span>
    </div>

    <div v-if="hasSubTrees(node)">
      <div v-for="(value, key, index) in node" v-bind:key="index">
        <div
          class="entry"
          v-for="extra in value.extras"
          v-bind:key="extra.id"
          v-if="hasSubTrees(node) && hasExtras(value) && !hasOperator(value)"
        >
          <label
            :for="'modal-' + extra.id"
            :class="
              hasSubTrees(node) && hasExtras(value) && hasAndOperator(node)
                ? 'disabled'
                : ''
            "
            v-if="hasSubTrees(node) && hasExtras(value) && hasOperator(node)"
            >{{ extra.label }} ({{ extra.option_code }})</label
          >
          <input
            :id="'modal-' + extra.id"
            type="checkbox"
            disabled="true"
            class="purple-radio"
            v-model="extra.selected"
            v-if="hasSubTrees(node) && hasExtras(value) && hasAndOperator(node)"
            v-on:change="checkRequirements"
            v-default="extra"
          />
          <input
            :id="'modal-' + extra.id"
            type="radio"
            :name="levelClass"
            :disabled="levelDisabled"
            v-model="selectedExtraId[level].id"
            :value="extra.id"
            class="purple-radio"
            v-if="hasSubTrees(node) && hasExtras(value) && hasOrOperator(node)"
            v-on:change="selectedExtraChanged(node)"
          />
          <span class="price">
            <span v-if="extra.hidden_costs != null && extra.hidden_costs.gross">{{
              extra.hidden_costs.gross | formatCurrency
            }}&nbsp;€</span>
            <span v-if="extra.hidden_costs == null && extra.price_gross">{{
              extra.price_gross | formatCurrency
            }}&nbsp;€</span>
          </span>
        </div>
        <div class="entry" v-if="hasSubTrees(node) && hasOperator(value)">
            <input v-if="hasSubTrees(node) && hasOrOperator(node)" type="radio" :name="this.currentLevel" class="purple-radio" />
            <div v-if="hasSubTrees(node) && hasOperator(node)">
                <RequirementsTree :level="currentLevel+1" :node="value" :checkRequirements="checkRequirements" v-if="hasSubTrees(node) && hasOperator(node)" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: 'RequirementsTree',
  created() {
    this.selectedExtraId[this.currentLevel] = { id: "" };
    this.levelDisabled = false;

    if (!this.hasSubTrees(this.node) && this.hasExtras(this.node) && this.hasOrOperator(this.node)) {
      this.selectedExtra = lodash.filter(this.node.extras, {selected: true,})[0];

      if (this.selectedExtra) {
        this.selectedExtraId[this.currentLevel].id = this.selectedExtra.id;

        if (this.selectedExtra.disabled) {
          this.levelDisabled = true;
        }
      }
    }

  },
  methods: {
    selectedExtraChanged(node) {
      let vm = this;
      lodash.forEach(node.extras, function (extra) {
        extra.selected = extra.id === vm.selectedExtraId[vm.currentLevel].id;
      });

      this.$props.checkRequirements();
    },
    hasExtras(node) {
      return Object.prototype.hasOwnProperty.call(node, "extras");
    },    
    hasSubTrees(node) {
      return Object.prototype.hasOwnProperty.call(node, "0");
    },
    hasAndOperator(node) {
      return (
        Object.prototype.hasOwnProperty.call(node, "operator") &&
        node.operator === "&&"
      );
    },
    hasOrOperator(node) {
      return (
        Object.prototype.hasOwnProperty.call(node, "operator") &&
        node.operator === "||"
      );
    },
    hasOperator(node) {
      return this.hasAndOperator(node) || this.hasOrOperator(node);
    },
    orderByLabel(list) {
      return lodash.orderBy(list, ["label"]);
    },
  },
  data() {
    return {
      levelDisabled: false,
      selectedExtraId: [],
      selectedExtra: undefined,
    };
  },
  computed: {
    currentLevel() {
      return this.level;
    },
    levelClass() {
      return "level-" + this.currentLevel;
    },
    orderedExtras() {
      if(this.node && this.node.extras) {
        return lodash.orderBy(this.node.extras, ["label"]);
      }

      return [];
    }
  },
  props: ["node", "checkRequirements", "level"],
  directives: {    
    default: {
        /* eslint-disable no-debugger */
      bind(el, binding, vnode) {    
        vnode.context.$set(binding.value, "selected", true);
      },
    },
  },

};
</script>

<style>
</style>