import api from '../../services/ApiService'
import store from '../store'


const state = {
    trimLevels: [],
};

const getters = {
    trimLevels() {
        return state.trimLevels
    } 
};

const actions = {    
    fetchTrimLevels({commit}, bodyDesignId) {
        store.dispatch('loading/setLoading', true);
        return api.getTrimLevels(bodyDesignId).then(trimLevels => {            
            commit('setTrimLevels', trimLevels);
            store.dispatch('loading/setLoading', false);
        });
    }
};

const mutations = {
    setTrimLevels(state, trimLevels) {
        state.trimLevels = trimLevels;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};