import config from "./../config";

export default {
    buildImageUrl(obj) {
        let dbName = "SSCD" in obj.photo_path ? "SSCD" : "NSCD";

        return (
            config.apiUri +
            "/ngphotos/" +
            dbName +
            "/" +
            obj.photo_path[dbName] +
            "/" +
            obj.photo_filename[dbName] +
            ".JPG"
        );
    },
    buildImageUrlForPerspective(obj, perspective, color) {
        if(!obj) {
            return null;
        }
        let dbName = "SSCD" in obj.photo_path ? "SSCD" : "NSCD";

        let imagePath = config.apiUri +
        "/ngphotos/" +
        dbName +
        "/" +
        obj.photo_path[dbName] +
        "/" +
        obj.photo_filename[dbName] +
        "_"+perspective;

        if(color) {
            imagePath = imagePath + '_' + color.option_code;
        }

        imagePath = imagePath + ".JPG";

        return imagePath;
    }
}