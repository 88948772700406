<template>
  <b-container fluid class="equipment vh-100 equipment-container">

    <b-row class="d-flex w-100 p-0">

      <b-col class="p-0">

        <b-col class="progress-bar-container p-0">
          <div class="progress">
            <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="60" aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </b-col>

        <b-col class="p-0">
          <div class="accordion" style="">
            <div class="card w-100">

              <div class="card-header pb-0 px-0 w-100 category-accordion" :id="('card-header-' + index)"
                v-for="(selectedCategory, index) in categories" :key="selectedCategory">

                <div>
                  <button class="w-100 btn btn-link p-0" v-b-toggle="('collapse-' + index)"
                    @click="toggleCollapse(selectedCategory)">
                    <div class="category-title">
                      <b-row class="py-4">
                        <b-col class="text-left">
                          <div>{{ selectedCategory }}</div>
                        </b-col>
                        <b-col class="text-right">
                          <font-awesome-icon :icon="collapsed[selectedCategory] ? 'minus' : 'plus'" />
                        </b-col>
                      </b-row>
                    </div>
                  </button>
                  <b-collapse v-for="(e, _index) in categorizedEquipment[index].equipment" v-bind:key="e.id"
                    :id="('collapse-' + index)"
                    :class="e.selected ? ' selected' : e.disabled ? ' disabled' : ' '"
                    v-bind:title="e.label" class="equipment text-left">
                    <div :class="['category-collapse-item equipment-property', e.selected ? ' selected' : e.disabled ? ' disabled' : '']" v-if="(e.category_name==selectedCategory)">
                      <div class="col d-flex m-0 p-0">
                        <div class="col-*" v-on:click="equipmentOptionClicked($event, _index, e, selectedCategory)">
                          <input type="checkbox" :id="'radio-' + engine.id" :name="selectedCategory" class="purple-checkbox" :checked="e.selected==true" :disabled="e.disabled==true">
                        </div>
                        <div :for="'radio-' + engine.id"
                          :class="e.selected ? ' selected' : e.disabled ? ' disabled' : 'col-*'"
                          class="title"
                          v-on:click="equipmentOptionClicked($event, _index, e, selectedCategory)">
                          {{ e.label }}
                        </div>
                        <a href="#" v-if="e.description && e.description.length > 0"
                          @click.prevent="showEquipmentInfos(e)">
                          <div class="title col-2"><b-icon icon="info-circle"></b-icon></div>
                        </a>
                      </div>
                      <p class="h6 bold" pill v-if="e.price_gross">{{ e.price_gross | formatCurrency }}&nbsp;€</p>
                    </div>
                  </b-collapse>

                </div>
              </div>
            </div>

          </div>
        </b-col>
      </b-col>
    </b-row>
    <!-- MODAL mehr erfahren -->

    <b-modal id="cd-equipmentinfo-modal" title="Details zur sonderausstattung">
      <template #default="{}">
        <b-container>
          <b-col>
            <ul>
              <li v-for="(d, i) in currentEquipment.description" v-bind:key="i">
                {{ d }}
              </li>
            </ul>
          </b-col>
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <button @click="ok" class="btn cancel-button"><span aria-hidden="true">&times;</span>Schließen</button>
      </template>
    </b-modal>

    <!-- MODAL mehr erfahren -->




    <!-- MODAL Achtung -->


    <b-modal id="cd-equipment-modal" title="Achtung">
      <p>Bitte wählen Sie eine Farbe.</p>
      <template #modal-footer="{ ok }">
        <button @click="ok()" class="btn cancel-button"><span aria-hidden="true">&times;</span>Abbrechen</button>
      </template>
    </b-modal>

    <!-- MODAL Achtung -->


    <!-- MODAL Bitte wählen -->

    <b-modal id="cd-equipment-selection" title='Ihre Auswahl ist nur mit folgenden Kombinationen möglich. Bitte passen Sie ihre Konfiguration entsprechend an.' @ok="shouldSelectExtra"
      @hide="onCancelExtraSelection">
      <template #default="{}">
        <b-container>
          <RequirementsTree :level="0" :node="requirements" :checkRequirements="checkRequirements" />
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <button @click="ok" class="btn ok-button" :disabled="!enable">Bestätigen</button>
        <button @click="hideEquipmentModal" class="btn cancel-button"><span aria-hidden="true">&times;</span>Abbrechen</button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import api from "../../services/ApiService";
import { mapGetters, mapActions } from "vuex";
import store from "../../data/store";
import ExtrasService from "../../services/ExtrasService";
import lodash from "lodash";
import RequirementsTree from "../elements/RequirementsTree.vue";
import ExpressionParser from "../../lib/expressionParser";
import ExpressionHelper from "../../lib/expressionHelper";
import { PriceCalculation, createCarConfigurationObject } from '../../services/PriceCalculationService';

export default {
  created() {
    this.$store.commit("setProgress", {
      progress: -1,
      title: "",
      subline: "Fügen Sie Ihre optionale Ausstattung hinzu.",
    });
    this.$store.commit("setShowCarInfo", false);
    this.$store.commit("setSecondProgress", "2. Ausstattung");
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  beforeDestroy() {
    this.$store.commit("nextActions/setCurrentAction", "");
  },
  mounted() {
    this.$store.commit("nextActions/setCurrentAction", "SetEngine");
    const vm = this;
    if (!this.$props.engineId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return api
                .getTrimLevel(bodyDesign.id, route.params.trimLevel)
                .then((trimLevel) => {
                  vm.$store.commit(
                    "carConfiguration/setCurrentTrimLevel",
                    trimLevel
                  );

                  return api
                    .getEngine(trimLevel.id, route.params.engine)
                    .then((engine) => {
                      return api
                        .getStandardEquipments(engine.id)
                        .then((equipments) => {
                          vm.$store.commit(
                            "carConfiguration/setStandardEquipments",
                            equipments
                          );
                          vm.$store.commit(
                            "carConfiguration/setCurrentEngine",
                            engine
                          );

                          if(!vm.equipment || vm.equipment.length === 0) {
                            vm.fetchEquipment(engine.id).then(() => {
                              return vm.categorizeEquipment(vm.currentExtras);
                            });
                          }else {
                            return vm.categorizeEquipment(vm.currentExtras);
                          }
                        });
                    });
                });
            });
        });
      });
    } else {
      return vm.fetchEquipment(this.$props.engineId).then(() => {
        return vm.categorizeEquipment(vm.currentExtras);
      });
    }
  },
  /* eslint-disable no-debugger */
  methods: {
    ...mapActions({
      fetchEquipment: "equipment/fetchEquipment",
      categorizeEquipment: "equipment/categorizeEquipment",
      saveConfiguration: "carConfiguration/saveConfiguration",
    }),
    toggleCollapse(title) {
      if (title in this.collapsed)
        this.collapsed[title] = !this.collapsed[title]
      else {
        this.collapsed = {
          ...this.collapsed,
          [title]: true
        }
      }
    },
    hideEquipmentModal() {
      this.$bvModal.hide("cd-equipment-selection");
    },
    equipmentOptionClicked(event, index, e, _category) {
      if (e.disabled) {
        return;
      }
      this.extraCategory = _category
      this.extraIndex = index
      if (_category == "Farben"){
        // event.preventDefault()
      } else {
        // event.preventDefault()
      }
      this.toggleEquipment(e)
    },
    showEquipmentInfos(equipment) {
      this.currentEquipment = equipment;
      this.$bvModal.show("cd-equipmentinfo-modal");
    },
    toggleEquipment(equipment) {
      const vm = this;

      if(this.isEquipmentDisabled(equipment)) {
        return;
      }
      equipment.selected = !equipment.selected;
      // const _equipment = {
      //   ...equipment,
      //   selected: !equipment.selected
      // }
      // vm.$store.commit("equipment/toggleEquipment", equipment);
      this.onExtraAdded(equipment, () => {
        vm.$store.commit("carConfiguration/setCurrentExtras", vm.equipment);
        vm.saveConfiguration().then(() => {
          lodash.forEach(vm.equipment, (extra) => {   
            ExtrasService.applyNewPrice(extra, vm.equipment);
          });
        });

      });
    },
    onExtraAdded(extra, added) {
      let vm = this;

      lodash.forEach(vm.equipment, (ex) => {
        ExtrasService.applyNewPrice(ex, vm.equipment);
      });
      if (extra.rules.length > 0) {
        if (extra.selected) {
          var result = ExtrasService.meetsRequirements(extra, vm.equipment);
          if (result.meetsRequirements) {
            ExtrasService.applyInclusions(extra, vm.equipment);
            ExtrasService.applyExclusions(extra, vm.equipment);
            if (added) {
              added();
            }
          } else {
            this.showRequirementsModal(extra, vm.equipment, result.rule).then(addedExtras => {
              ExtrasService.applyInclusions(extra, vm.equipment);
              ExtrasService.applyExclusions(extra, vm.equipment);
              
              let addedExtra = null;
              lodash.forEach(addedExtras, (addedExtra) => {
                let extraInListSource = lodash.filter(vm.equipment, {id: addedExtra.id})[0];
                extraInListSource.selected = true;
                extraInListSource.requiredBy = extra.id;
                extraInListSource.disabled = true;
              });

              let iterateAddedExtras = (index) => {
                if (index >= addedExtras.length) {
                  if (added) {
                    added();
                  }

                  return;
                }

                addedExtra = addedExtras[index];

                let extraInListSource = lodash.filter(vm.equipment, {id: addedExtra.id})[0];

                vm.onExtraAdded(extraInListSource, () => {
                  if (extraInListSource.selected) {
                    vm.addedExtras.push(addedExtra);

                    iterateAddedExtras(index + 1);
                  } else {
                    extraInListSource.requiredBy = '';
                    extraInListSource.disabled = false;

                    extra.selected = false;
                    ExtrasService.undoRequirements(extra, vm.equipment);
                    ExtrasService.undoInclusions(extra, vm.equipment);
                    ExtrasService.undoExclusions(extra, vm.equipment);
                  }
                });
              };

              iterateAddedExtras(0);
              vm.$forceUpdate();
            }, () => {
            
              extra.selected = false;
                            
              ExtrasService.undoRequirements(extra, vm.equipment);
              ExtrasService.undoInclusions(extra, vm.equipment);
              ExtrasService.undoExclusions(extra, vm.equipment);

              if (added) {
                added();
              }
            });
          }
        }else {
           // item was de-selected:
          ExtrasService.undoRequirements(extra, vm.equipment);
          ExtrasService.undoInclusions(extra, vm.equipment);
          ExtrasService.undoExclusions(extra, vm.equipment);
          extra.selected = false;
          
          if (added) {
            added();
          }
        }
      }
      else {
        if (added)
          added()
      }
    },
    showRequirementsModal(extra, extras, rule) {
      let vm = this;
      this.rule = rule.rule;
      /* eslint-disable no-unused-vars */
      let p = new Promise((resolve, reject) => {
        vm.copiedExtras = JSON.parse(JSON.stringify(extras));
        vm.requirements = ExpressionParser.toExpressionTree(rule.rule, vm.copiedExtras);
        vm.$bvModal.show("cd-equipment-selection");
        vm.dialogPromiseResolve = resolve;
        vm.dialogPromiseReject = reject;
      });

      return p;
    },
    getEquipmentClass(equipment) {
      const isSelected = equipment.selected;

      let classNames = "cd-box-component cd-box-color equipment ";

      if (isSelected) {
        classNames += "selected";
      }

      if(this.isEquipmentDisabled(equipment)) {
        classNames += ' disabled';
      }

      return classNames;
    },
    saveCarConfiguration() {
      //this.$store.commit("carConfiguration/setCurrentMaker", maker);
      var vm = this;

      if (!this.hasSelectedColor) {
        this.$bvModal.show("cd-equipment-modal");
        return;
      }

      store.dispatch("loading/setLoading", true);

      this.equipment.forEach((extra) => {
        extra.price_gross = extra.price_gross ? extra.price_gross : 0;
        extra.price_net = extra.price_net ? extra.price_net : 0;
      });

      vm.$store.commit(
        "carConfiguration/setCurrentExtras",
        this.equipment
      );
      
      let calcObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      let result = PriceCalculation.calculateDiscountedPrice(calcObj);

      vm.$store.commit('carConfiguration/setDiscountedPrice', result);
      vm.saveConfiguration().then(() => {
        store.dispatch("loading/setLoading", false);

        

        vm.$router.push({
          name: "summary",
          params: { configurationId: this.configurationId },
        });
      });
    },
    checkRequirements() {
      this.meetsRequirements = ExpressionHelper.meetsCondition(
        this.equipment,
        this.rule
      );
    },
    shouldSelectExtra() {
      console.log('here')
      let addedExtras = ExpressionHelper.getExtras(this.rule, this.copiedExtras);
      addedExtras.forEach(extra => console.log(extra.selected))
      if(this.dialogPromiseResolve) {
        this.dialogPromiseWasResolved = true;
      
        let addedExtras = ExpressionHelper.getExtras(this.rule, this.copiedExtras);
        addedExtras = lodash.filter(addedExtras, {selected: true});

        this.dialogPromiseResolve(addedExtras);
      }
    },
    onCancelExtraSelection() {
      if(this.dialogPromiseReject && !this.dialogPromiseWasResolved) {        
        this.dialogPromiseReject();        
      }else {
        this.dialogPromiseWasResolved = false;
      }
    },
    isEquipmentSelected(equipment) {
      let isSelected = false;
      
      this.equipment.some((ce) => {
        if (ce.id === equipment.id) {
          isSelected = ce.selected;
          return true;
        }

        return false;
      });

      return isSelected;
    },
    isEquipmentDisabled(equipment) {
      return lodash.some(this.equipment, {id:equipment.id, disabled: true});
    }
  },
  computed: {
    ...mapGetters({
      categorizedEquipment: "equipment/categorizedEquipment",
      configurationId: "carConfiguration/configurationId",
      currentExtras: "carConfiguration/currentExtras",
      equipment: "equipment/equipment",
      model: 'carConfiguration/currentModel',
      bodyDesign: 'carConfiguration/currentBodyDesign',
      trimLevel: 'carConfiguration/currentTrimLevel',
      engine: 'carConfiguration/currentEngine',
      extras: 'carConfiguration/currentExtras',
      condition: 'carConfiguration/currentCondition'
    }),
    hasSelectedColor() {
      var chosenColor = lodash.filter(this.equipment, {category_name: 'Farben', selected: true})[0];
      return chosenColor != null;
    },
    currentCategoryEquipment() {
      let equipment = [];

      if (this.selectedCategory) {
        this.categorizedEquipment.some((c) => {
          if (c.category_name === this.selectedCategory) {
            equipment = c.equipment;
            return true;
          }

          return false;
        });
      }
      return equipment;
    },
  },
  watch: {
    categorizedEquipment() {
      this.categories = this.categorizedEquipment.map((c) => c.category_name);
    },
    requirements: {
      deep: true,
      handler() {
        this.enable = true
        let enable = false;
        if (this.requirements.extras) {
          this.requirements.extras.forEach(extra => {
            if (extra.selected) enable = true
          })
        }
        else if (this.requirements) {
          for (let i = 0;;i++) {
            if (i.toString() in this.requirements) {
              enable = false
              this.requirements[i].extras.forEach(extra => {
                if (extra.selected) enable = true
              })
              if (!enable) this.enable = false
            }
            else break
          }
        }
        this.enable = enable
      }
    },
    equipment: {
      deep: true,
      handler() {
        if (this.equipment) {
          let price = 0
          this.equipment.forEach(e => {
            if (e.selected && e.price_gross)
              price += e.price_gross
          })
          this.$store.commit("nextActions/setExtraPrice", price);
        }
      }
    }
  },
  data() {
    return {
      enable: true,
      categories: [],
      preSelection: [],
      selectedCategory: undefined,
      currentEquipment: undefined,
      selectedExtras: [],
      selectedExtrasFromDialog: [],
      requirements: undefined,
      copiedExtras: undefined,
      meetsRequirements: false,
      rule: undefined,
      dialogPromiseResolve:undefined,
      dialogPromiseReject: undefined,
      dialogPromiseWasResolved: false,
      addedExtras: [],
      extraIndex: 0,
      extraCategory: "",
      extra: 0,
      collapsed: {}
    };
  },
  props: ["engineId"],
  components: {
    RequirementsTree,
  },
};
</script>


<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style scoped src="vue-select/dist/vue-select.css"></style>
<style scoped src="bootstrap-icons/font/bootstrap-icons.css"></style>

<style>

/* NEW SYTLE */

/*.equipment {
  min-width: 22.5rem !important;
}*/

.equipment h4{
  font-size: 1.2rem;
}

.equipment.selected {
  border-color: var(--primary);
}

.equipment.disabled {
  /* background-color: var(--light); */
}

.equipment-property.disabled * {
  color: #8d8d8d !important;
}

.equipment-property.disabled input:after,
.equipment-property.disabled input::after {
  border-color: #8d8d8d !important;
}

.bi-info-circle::before { content: "\f431"; }


/* ALTES Styling */



.row.cd-equipment-cards .card .card-text a,
.row.cd-equipment-cards .card .card-text a:hover {
  color: #496cf6;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.cd-equipment-label {
  font-weight: 600;
  font-size: .9rem;
  text-align: left !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cd-equipment-price-info {
  text-align: left !important;
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

/*
#cd-equipmentinfo-modal h5.cd-headline {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

#cd-equipmentinfo-modal p.cd-subline {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}

#cd-equipmentinfo-modal .cd-equipment-info-list {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  padding-left: 15px;
  list-style: square;
}

#cd-equipmentinfo-modal .modal-body {
  padding-top: 0px;
  margin-top: 40px;
}
*/

.cd-button.cd-std-button.cd-next-button {
  margin: auto !important;
  word-wrap:  break-word;
}

#cd-equipment-selection .entry {
  position: relative;
  border-radius: 4px;
  margin: 3px 0;
  padding: 5px 15px;
  padding-left:30px;
  font-size: 16px;
  min-height: 52px;
  font-weight: 700;
}

#cd-equipment-selection .entry label {
  width: 100%;
  display: block;
  padding-right: 30%;
  position: relative;
  margin: 0 !important;
  overflow: unset;
}

#cd-equipment-selection .entry input[type="checkbox"],
#cd-equipment-selection .entry input[type="radio"] {
  position: absolute;
  left: 0;
  margin: 6px 0 0;
  top: 0;
}

#cd-equipment-selection .entry .price {
  right: 15px !important;
  top: 8px;
  position: absolute;
}

#cd-equipment-selection .entry .price span {
  font-weight: bold;
}


/* ALLES AUTO */

.category-accordion {
  border-bottom: 3px solid #c6c6c6 !important;
  padding: 26px 0;
}

.category-accordion:last-child {
  border-bottom: none !important;
}

button:focus {
  outline: none !important;
  border:none;
  box-shadow: none !important;
}

.category-title {
  font-size: 16px;
  font-family: 'GintoNord-Bold', sans-serif !important;
}


.equipment:last-child {
    margin-bottom: 20px;
}

.category-title svg {
  width: 20px;
  height: 20px;
  float: right;
  font-family: 'GintoNord-Bold', sans-serif;

}

.category-collapse-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
  align-items: center;
  color: var(--alles-auto-primary);
  flex-direction: row;
}

.category-collapse-item input {
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.category-collapse-item .title {
  margin-right: 6px;
  font-weight: 400;
  font-size: 16px;
  color: var(--alles-auto-primary);
  margin-left: 12px;
}

.equipment-container {
  padding-bottom: 100px;
  overflow: auto;
}

.disabled .title input {
  border: 1px solid #8d8d8d;
}



.modal-content,
.modal-content div {
  background-color: #1DFFCD !important;
  color: var(--alles-auto-primary);
  width: auto;
  overflow: overlay;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
}


.modal-header {
display: flex;
align-items: flex-start;
justify-content: space-between;
padding: 1rem 1rem;
border-bottom: none;
border-top-left-radius: calc(0.3rem - 1px);
border-top-right-radius: calc(0.3rem - 1px);
}


#cd-equipment-selection .modal-header,
#cd-equipmentinfo-modal .modal-header{
  padding: 41px 25px 41px 80px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: none !important;
}

#cd-equipment-selection .modal-body,
#cd-equipmentinfo-modal .modal-body{
  flex: 1;
  overflow-y: auto;
}

#cd-equipment-selection .modal-body,
#cd-equipmentinfo-modal .modal-body {
  padding-left: 80px;
  padding-right: 80px;
}

#cd-equipment-selection .modal-body .container,
#cd-equipmentinfo-modal .modal-body .container {
  padding: 0 !important;
}

.modal-dialog {
    max-width: 100%;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100000;
    border-radius: none;
    max-height: 100vh;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-left: 80px;
  padding-right: 80px;
}

.modal-body .container {
  max-width: 100% !important;
  padding: 0 !important;
}

.modal-dialog {
    max-width: 100%;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 100000;
    border-radius: none;
    max-height: 100vh;
}

.close {
font-size: 20px;
color: #000;
text-shadow: 0 1px 0 #fff;
opacity: .5;
}

button.close {
padding: 0;
background-color: transparent;
border: 0;
}


button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

.equipment [type="checkbox"] {
  left: 0px;
}



#cd-equipment-selection footer,
#cd-equipmentinfo-modal footer {
  border-top: none !important;
  padding-top: 15px;
  padding-left: 80px;
  padding-bottom: 20px;
  justify-content: flex-start !important;
}

.modal-body .container {
  max-width: 100% !important;
  padding: 0 !important;
}

.ok-button {
  width: 164px;
  height: 50px;
  border-radius: 4px;
  font-weight: 700;
  background-color: var(--alles-auto-primary) !important;
  margin-right: 15px !important;
  color: white !important;
  padding: 15px 40px;
  gap: 20px;
  display: flex;
}

.ok-button:disabled {
  background-color: #c6c6c6 !important;
  cursor: not-allowed;
}

.cancel-button {
  font-size: 16px;
  line-height: 20px;
}

.cancel-button span {
  margin-right: 9px !important;
}

.btn-link:hover {
  text-decoration: none !important;
}


#cd-equipment-selection .modal-dialog,
#cd-equipment-modal .modal-dialog,
.modal-dialog {
  left: 50%;
}


/* Responsive fixes */


@media screen and (max-width: 576px) {
  .modal-header {
    padding: 15px 20px;
  }

  #cd-equipment-selection footer {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  #cd-equipment-selection .modal-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  footer {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1199px) {
  #cd-equipment-selection .modal-dialog,
  #cd-equipment-modal .modal-dialog,
  #equipmentinfo-modal .modal-dialog {
    width: 100% !important;
    left: 0 !important;
  }
}

</style>