import lodash from 'lodash';

export default {
    getExtraIds(expression) {
        return expression.match(/\d+/g);
    },
    getExtras(expression, extrasSource) {
        var self = this;
        var result = [];
      
        var extraIds = self.getExtraIds(expression);
      
        lodash.forEach(extraIds, function (extraId) {
          extraId = Number(extraId);
          result = result.concat(lodash.filter(extrasSource, {option_id: extraId}));
        });
      
        return result;
      },
      meetsCondition(itemSource, condition) {
        var expressionItems = condition.match(/\d+/g);
      
        if (expressionItems.length === 0) {
          return false;
        } else {
      
          var check = condition.replace(/[{}]/gi, '');
      
          lodash.forEach(expressionItems, function (expressionItem) {
            var extra = lodash.filter(itemSource, {option_id: parseInt(expressionItem)})[0];
            var value = extra.selected ? 'true' : 'false';
      
            check=check.replace(expressionItem, value);
          });
      
          check=check.replace(/AND/g, '&&');
          check=check.replace(/OR/g, '||');
          check=check.replace(/NOT/g, '!');
      
          return (eval(check) === true);
        }
      }
}