import UidService from "../../services/UidService";
import ApiService from "../../services/ApiService";
import {PriceCalculation, createCarConfigurationObject} from '../../services/PriceCalculationService'


function createSaveRequest(state) {

    let configurationObj = createCarConfigurationObject(state.currentEngine, state.currentTrimLevel, state.currentBodyDesign, state.currentModel, state.currentExtras, null);
    let result = PriceCalculation.calculateTotalPrice(configurationObj);
    let requestObj = {
        car_configuration: {
            external_id: state.configurationId,
            id: state.configurationUid,
            price_net: result.net,
            price_gross: result.gross,
            version: state.version,
            data: {
                maker: state.currentMaker,
                model: state.currentModel,
                bodyDesign: state.currentBodyDesign,
                engine: state.currentEngine,
                trimLevel: state.currentTrimLevel,
                extras: state.currentExtras,
                standardEquipments: state.currentStandardEquipments,
                contactData: state.currentContactData,       
                condition: state.currentCondition,
                discountedPrice: state.discountedPrice
            }
        }
    };

    if(!requestObj.car_configuration.data.contactData) {
        requestObj.car_configuration.data.contactData = {};
    }
    requestObj.car_configuration.data.contactData.pdfLink = window.location.origin + '/konfigurator/#/'+state.configurationId+'/Zusammenfassung/pdf';

    return requestObj;
}

const state = {
    configurationUid: undefined,
    configurationId: undefined,
    currentMakerId: undefined,
    currentModelId: undefined,
    currentBodyDesignId: undefined,
    currentMaker: undefined,
    currentModel: undefined,
    currentBodyDesign: undefined,
    currentTrimLevel: undefined,
    currentEngine: undefined,
    currentExtras: [],
    currentContactData: undefined,
    currentStandardEquipments: undefined,
    currentCondition: undefined,
    priceNet: 0,
    priceGross: 0,
    discountedPrice: undefined,
    version:0
};

const getters = {
    currentMakerId() {
        return state.currentMakerId;
    },
    currentModelId() {
        return state.currentModelId;
    },
    currentBodyDesignId() {
        return state.currentBodyDesignId;
    },
    currentMaker() {
        return state.currentMaker;
    },
    currentModel() {
        return state.currentModel;
    },
    currentBodyDesign() {
        return state.currentBodyDesign;
    },
    currentTrimLevel() {
        return state.currentTrimLevel;
    },
    currentEngine() {
        return state.currentEngine;
    },
    configurationId() {
        return state.configurationId;
    },
    configurationUid() {
        return state.configurationUid;
    },  
    currentExtras() {
        return state.currentExtras;
    },
    currentContactData() {
        return state.currentContactData;
    },
    priceNet() {
        return state.priceNet;
    },
    priceGross() {
        return state.priceGross;
    },
    standardEquipments() {
        return state.currentStandardEquipments;
    },
    currentCondition() {
        return state.currentCondition;
    },
    discountedPrice() {
        return state.discountedPrice;
    },
    version() {
        return state.version;
    }
};
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
const actions = {
    reset({commit}) {
        commit('configurationId', undefined);
        commit('setConfigurationUid', undefined);
        commit('setCurrentMaker', undefined);
        commit('setCurrentModel', undefined);
        commit('setCurrentEngine', undefined);
        commit('setCurrentTrimLevel', undefined);
        commit('setCurrentExtras', undefined);
        commit('setCurrentContactData', undefined);
        commit('setStandardEquipments', undefined);
        commit('setCurrentCondition', undefined);
        commit('setDiscountedPrice', undefined);
        commit('currentBodyDesign', undefined);
        commit('priceGross', undefined);
        commit('priceNet', undefined);
        commit('version', 0);
    },
    loadConfiguration({commit}, configurationId) {
   
        return ApiService.getCarConfiguration(configurationId).then(carConfiguration => {
            let configuration = carConfiguration[0];
            let promises = [];
         
            promises.push(commit('configurationId', configuration.external_id));
            promises.push(commit('setConfigurationUid', configuration.id));            
            promises.push(commit('setCurrentMaker', configuration.data.maker));
            promises.push(commit('setCurrentModel', configuration.data.model));
            promises.push(commit('setCurrentBodyDesign', configuration.data.bodyDesign));
            promises.push(commit('setCurrentEngine', configuration.data.engine));
            promises.push(commit('setCurrentTrimLevel', configuration.data.trimLevel));
            promises.push(commit('setCurrentExtras', configuration.data.extras));
            promises.push(commit('setCurrentContactData', configuration.data.contactData));
            promises.push(commit('setStandardEquipments', configuration.data.standardEquipments));
            promises.push(commit('setCurrentCondition', configuration.data.condition));
            promises.push(commit('setDiscountedPrice', configuration.data.discountedPrice));
            promises.push(commit('setVersion', configuration.id));
            
            return Promise.all(promises);
        });
    },
    updateCurrentConfiguration({commit}, configuration) {
        commit('configurationId', configuration.external_id);
        commit('setConfigurationUid', configuration.id);
        commit('setCurrentMaker', configuration.data.maker);
        commit('setCurrentModel', configuration.data.model);
        commit('setCurrentBodyDesign', configuration.data.bodyDesign);
        commit('setCurrentEngine', configuration.data.engine);
        commit('setCurrentTrimLevel', configuration.data.trimLevel);
        commit('setCurrentExtras', configuration.data.extras);
        commit('setCurrentContactData', configuration.data.contactData);
        commit('setStandardEquipments', configuration.data.standardEquipments);
        commit('setCurrentCondition', configuration.data.condition);
        commit('setDiscountedPrice', configuration.data.discountedPrice);
        commit('setVersion', configuration.version);
    },
    saveConfiguration({dispatch}) {         
        let request = createSaveRequest(state);        
        if(!request.car_configuration.external_id) {
            return dispatch('generateUid').then(() => {
                request.car_configuration.external_id = state.configurationId;
                return ApiService.saveCarConfiguration(request).then(c => {
                    dispatch("updateCurrentConfiguration", c);
                });
            });
        }else {
            return ApiService.updateCarConfiguration(request).then(c => {
                dispatch("updateCurrentConfiguration", c);
            });
        }
    },
    generateUid({commit}) {
        return commit('configurationId', UidService.generateUid());
    }    
};

const mutations = {
    setCurrentMakerId(state, makerId) {
        state.currentMakerId = makerId;
    },
    setCurrentModelId(state, modelId) {
        state.currentModelId = modelId;
    },
    setCurrentBodyDesignId(state, bodyDesignId) {
        state.currentBodyDesignId = bodyDesignId;
    },
    setCurrentMaker(state, maker) {
        state.currentMaker = maker;
    },
    setCurrentModel(state, model) {
        state.currentModel = model;
    },
    setCurrentBodyDesign(state, bodyDesign) {
        state.currentBodyDesign = bodyDesign;
    },
    setCurrentTrimLevel(state, trimLevel) {
        state.currentTrimLevel = trimLevel;
    },
    setCurrentEngine(state, engine) {
        state.currentEngine = engine;
    },
    configurationId(state, configurationId) {
        state.configurationId = configurationId;
    },
    setConfigurationUid(state, configurationUid) {
        state.configurationUid = configurationUid;
    },
    setCurrentExtras(state, currentExtras) {
        state.currentExtras = currentExtras;
    },
    setCurrentContactData(state, currentContactData) {
        state.currentContactData = currentContactData;
    },
    setPriceNet(state, price) {
        state.priceNet = price;
    },
    setPriceGross(state, price) {
        state.priceGross = price;
    },
    setStandardEquipments(state, equipments) {
        state.currentStandardEquipments = equipments;
    },
    setCurrentCondition(state, condition) {
        state.currentCondition = condition;
    },
    setDiscountedPrice(state, discountedPrice) {
        state.discountedPrice = discountedPrice;
    },
    setVersion(state, version) {
        state.version = version;
    },
    setEnginePrice(state, price) {
        state.currentModel.min_price_gross = price
    }
}; 

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};