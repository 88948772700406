import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

import auth from './modules/auth';
import loading from './modules/loading';
import makers from './modules/makers';
import models from './modules/models';
import bodyDesigns from './modules/bodyDesigns';
import carConfiguration from './modules/carConfiguration';
import trimLevels from './modules/trimLevels';
import engines from './modules/engines';
import equipment from './modules/equipment'
import conditions from './modules/conditions';
import nextActions from './modules/nextActions';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
    maxProgress: 8,
    progress: 0,
    progressTitle: '',    
    progressSubline: '',
    showEquipmentCompairsionLink: false,
    showCarInfo: false,
    showSidebar: false,
    showOfferLink: false,
    privacyPolicyUrl: '',
    offerSubmitted: false,
    secondProgress: '',
    predefinedMake: '',
    predefinedModel: '',
    predefinedBodyDesign: '',
    predefinedTrimLevel: ''
};

export default new Vuex.Store({
    state,
    actions: {

    },
    getters: {        
        progress() {
            return state.progress + '. ' + state.progressTitle;
        },
        currentProgress() {
            return state.progress;
        },
        maxProgress() {
            return state.maxProgress;
        },
        progressSubline() {
            return state.progressSubline;
        },
        showEquipmentCompairsionLink() {
            return state.showEquipmentCompairsionLink;
        },
        showCarInfo() {
            return state.showCarInfo;
        },
        showSidebar() {
            return state.showSidebar;
        },
        showOfferLink() {
            return state.showOfferLink;
        },
        privacyPolicyUrl() {
            return state.privacyPolicyUrl;
        },
        offerSubmitted() {
            return state.offerSubmitted;
        },
        secondProgress() {
            return state.secondProgress;
        },
        predefinedMake() {
            return state.predefinedMake;
        },
        predefinedModel() {
            return state.predefinedModel;
        },
        predefinedBodyDesign() {
            return state.predefinedBodyDesign;
        },
        predefinedTrimLevel() {
            return state.predefinedTrimLevel;
        }
    },
    mutations: {
        setProgress(state, payload) {
            state.progress = payload.progress;
            state.progressTitle = payload.title;
            state.progressSubline = payload.subline;
        },
        setSecondProgress(state, payload) {
            state.secondProgress = payload;
        },
        setShowEquipmentCompairsionLink(state, show) {
            state.showEquipmentCompairsionLink = show;
        },
        setShowCarInfo(state, showCarInfo) {
            state.showCarInfo = showCarInfo;
        },
        setShowSidebar(state, showSidebar) {
            state.showSidebar = showSidebar;
        },
        setShowOfferLink(state, showOfferLink) {
            state.showOfferLink = showOfferLink;
        },
        setPrivacyPolicyUrl(state, privacyPolicyUrl) {
            state.privacyPolicyUrl = privacyPolicyUrl;
        },
        setOfferSubmitted(state, offerSubmitted) {
            state.offerSubmitted = offerSubmitted;
        },
        setPredefinedMake(state, make) {
            state.predefinedMake = make;
        },
        setPredefinedModel(state, model) {
            state.predefinedModel = model;
        },
        setPredefinedBodyDesign(state, bodyDesign) {
            state.predefinedBodyDesign = bodyDesign;
        },
        setPredefinedTrimLevel(state, trimLevel) {
            state.predefinedTrimLevel = trimLevel;
        },
    },
    modules: {
        auth,
        loading,
        makers,
        models,
        bodyDesigns,
        trimLevels,
        engines,
        carConfiguration,
        equipment,
        conditions,
        nextActions
    }
});