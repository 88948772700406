import expressionHelper from "./expressionHelper";
import lodash from 'lodash';

export default {
    getSubExpressions(expressionBase, startPosition) {
        var self = this;
        var result = [];
      
        startPosition = startPosition || 0;
      
        if (startPosition >= expressionBase.length) {
          return;
        }
      
        var newStartPosition = 0;
        var searchString = expressionBase.substr(startPosition, expressionBase.length);
        var openingBracketPosition = searchString.indexOf('(');
      
        var subExpression = '';
      
        if (openingBracketPosition == -1) {
      
          subExpression = searchString;
          var extraIds=expressionHelper.getExtraIds(subExpression);
      
          if (extraIds.length > 0) {
            result.push(subExpression.replace(/\s?(&&|\|\|)\s? /g, ''));
          }
      
        } else {
      
          if (openingBracketPosition > 0) {
            // there is an expression between startPosition and the found bracket:
            var beforeBracketString = searchString.substr(0, openingBracketPosition);
            result = result.concat(beforeBracketString.replace(/\s*(?:&&|\|\|)\s*/g, ' ').split(' ').filter(function(i){
              if (i)
                return true;
              return false;
            }));
      
            newStartPosition = startPosition + openingBracketPosition;
            // subExpression = searchString.substr(0, openingBracketPosition);
            // subExpression = subExpression.replace(/\s?(?:&&|\|\|)\s? /g, '')
      
          } else {
      
            // lets find the appropriate closing bracket for this expression
            var subSearchString = searchString.substr(openingBracketPosition + 1, searchString.length);
            var closingBracketsToSkip = 0;
      
            for (var i=0; i<subSearchString.length; ++i) {
      
              if (subSearchString[i] == '(') {
                closingBracketsToSkip++;
              }
      
              if (subSearchString[i] == ')') {
                if (closingBracketsToSkip > 0) {
                  closingBracketsToSkip--;
                } else {
      
                  newStartPosition = startPosition + openingBracketPosition + i+2;
      
                  // stripping the outer brackets, as we wont need them and they could cause trouble with
                  // sub-expressions
                  subExpression = searchString.substr(openingBracketPosition + 1, i + (subSearchString[i-1] === ')'));
      
                  break;
                }
              }
            }
          }
      
          if (subExpression.length > 0) {
            result.push(subExpression);
          }
      
          var subResult = self.getSubExpressions(expressionBase, newStartPosition);
      
          if (subResult) {
            result = result.concat(subResult);
          }
        }
      
        return result;
      },
      toExpressionTree(expression, extras) {
        // example ({2778} OR {2746}) AND ({2757} OR {2756}) AND {2416} AND ({2785} OR {2786} OR {2801} OR {2790} OR {2796} OR {2797} OR {2798} OR {2802}) AND {2338}
      
        var self = this;
      
        expression = expression.replace(/[{}]/g, '').replace(/AND/g, '&&').replace(/OR/g, '||');
      
        var result = {};
      
        var hasAndLinks = (expression.indexOf('&&') > -1);
        var hasOrLinks = (expression.indexOf('||') > -1);
      
        if (hasAndLinks && !hasOrLinks) {
          result.operator = '&&';
          result.extras = expressionHelper.getExtras(expression, extras);
      
          lodash.forEach(result.extras, function (extra) {
            extra.selected = true;
          });
        } else if (!hasAndLinks && hasOrLinks) {
          result.operator = '||';
          result.extras = expressionHelper.getExtras(expression, extras);
        } else if (!hasAndLinks && !hasOrLinks) {
          result.operator = '&&';
          result.extras = expressionHelper.getExtras(expression, extras);
      
          lodash.forEach(result.extras, function (extra) {
            extra.selected = true;
          });
        } else {
          var subExpressions = self.getSubExpressions(expression, 0);
      
          for (var i=0; i < subExpressions.length; ++i) {
            expression = expression.replace(subExpressions[i], '{' + i + '}');
          }
      
          hasAndLinks = (expression.indexOf('&&') > -1);
          hasOrLinks = (expression.indexOf('||') > -1);
      
          if (hasAndLinks && !hasOrLinks) {
            result.operator = '&&';
          } else if (!hasAndLinks && hasOrLinks) {
            result.operator = '||';
          }
      
          for (var j=0; j < subExpressions.length; j++) {
            hasAndLinks = (subExpressions[j].indexOf('&&') > -1);
            hasOrLinks = (subExpressions[j].indexOf('||') > -1);
      
            var node = (result[j.toString()] = {});
      
            if (hasAndLinks && !hasOrLinks) {
              node.operator = '&&';
              node.extras = expressionHelper.getExtras(subExpressions[j], extras);
      
              lodash.forEach(node.extras, function (extra) {
                extra.selected = true;
              });
            } else if (!hasAndLinks && hasOrLinks) {
              node.operator = '||';
              node.extras = expressionHelper.getExtras(subExpressions[j], extras);
            } else if (!hasAndLinks && !hasOrLinks) {
              node.extras = expressionHelper.getExtras(subExpressions[j], extras);
      
              lodash.forEach(node.extras, function (extra) {
                extra.selected = true;
              });
            } else {
              result[j.toString()] = self.toExpressionTree(subExpressions[j]);
            }
          }
        }
      
        return result;      
      }
}