<template>
  <b-container fluid>
    <b-row>
      <b-card-group
        v-bind:key="maker.id"
        v-for="maker in makers"
        class="p-2"
        >
          <b-card
            @click="selectMaker(maker)"
            img-top
          >
          <div img-src :class="makerImage(maker)"
            img-alt="maker.label">
            </div>
          <br />
          <br />
          <br />
            <b-card-text>
            </b-card-text>
            <template #footer>

              <small class="text-justify">{{maker.label}}</small>
            </template>
          </b-card>
      </b-card-group>
    </b-row>
  </b-container>
</template>



<script>
import { mapGetters, mapActions } from "vuex";
import store from "../../data/store";
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 1,
      title: "Marke",
      subline: "Bitte wählen Sie eine Marke",
    });
    this.fetchMakers();
  },
  mounted() {},
  /* eslint-disable no-debugger */
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  methods: {
    ...mapActions({
      fetchMakers: "makers/fetchMakers",
    }),
    selectMaker(maker) {
      this.$store.commit("carConfiguration/setCurrentMaker", maker);
      this.$router.push({
        name: "models",
        params: { maker: maker.label, makerId: maker.id },
      });
    },
    makerImage(maker) {

      let label = maker.label.replace('ŠKODA', 'SKODA').toLowerCase().replace(' ','-');
      return "maker-image img-" + label;
    },
  },
  computed: {
    ...mapGetters({
      makers: "makers/makers",
    }),
  },
};
</script>

<style>


</style>
