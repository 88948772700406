import config from '../config';

let engineConditions = new Map();
let conditions = new Map();

export default {
    initialize(conditionList) {
        conditions = conditionList;
    },
    hasSpecialPrice(data) {
        let cond = this.getConditions(data);

        if ((cond['discount'] != null && cond['discount'] != 0) ||
            (cond['reduction'] != null && cond['reduction'] != 0) ||
            (cond['leasing_factor'] != null && cond['leasing_factor'] != 0) ||
            (cond['deny'] != null && cond['deny'] != 0)) {
            return true;
        }

        return false;
    },
    hasConditions(data) {
        let cond = this.getConditions(data);

        for (let prop in cond) {
            if (cond[prop] != null) {
                return true;
            }
        }

        return false;
    },
    /* eslint-disable no-debugger */
    getNewPrice(oldPrice, data) {
   
        const cond = this.getConditions(data);
        let tax = config.pricing === 'net' ? 1.19 : 1;
        let price = oldPrice - (oldPrice * (cond.discount || 0) / 100) - (cond.reduction || 0) / tax

        return price;
    },
    getConditions(data) {

        let engine, body_design, trim_level, model;

        if (data.min_engine) {
            const key = Object.keys(data.min_engine)[0]
            const dataFeedAvailable = /^SS|NS/.test(key)

            if (dataFeedAvailable) {
                ({ engine, body_design, trim_level } = data.min_engine[key]);
                model = data.model_id || data.id;
            }
        } else {
            engine = data.id;
            body_design = data.body_design_id;
            trim_level = data.trim_level_id;
            model = data.model_id;
        }

        if (engineConditions.has(engine)) {
            return engineConditions.get(engine);
        }

        let engineCondition = conditions.get('engine:' + engine);
        let modelCondition = conditions.get('model:' + model);
        let bodyDesignCondition = conditions.get('body_design:' + body_design);
        let trimLevelCondition = conditions.get('trim_level:' + trim_level);

        let mergedConditions = this.mergeConditions(modelCondition, bodyDesignCondition, trimLevelCondition, engineCondition);

        engineConditions.set(engine, mergedConditions);

        return mergedConditions;
    },
    mergeConditions(...conditions) {
        let props = ['discount', 'reduction', 'provision_costs', 'leasing_factor', 'deny'];
    
        return conditions.reduce(function (result, c) {
            if (c == null) return result;
    
            props.forEach(function (prop) {
                if (c[prop] != null) {
                    result[prop] = c[prop];
                }
            });
    
            return result;
        }, {});
    }
}