<template>
  <b-container fluid>
    <b-row >
     <b-card-group
        v-bind:key="bodyDesign.id"
        v-for="bodyDesign in bodyDesigns"
        class="p-2"
      >
          <b-card
            :img-src="imageUriForModel(bodyDesign)"
            v-bind:img-alt="bodyDesign.label"
            @click="selectBodyDesign(bodyDesign)"
            v-bind:title="bodyDesign.label"
          >
            <b-card-text>
              <div>
                <p class="h3" pill>ab {{getNewPrice(bodyDesign["min_price_" + pricing], bodyDesign) | formatCurrency}}&nbsp;€</p>
                <p class="h6" style="text-decoration: line-through">uvp ab {{ bodyDesign.min_price_gross | formatCurrency }}&nbsp;€</p>
              </div>
            </b-card-text>
            <template #footer>
              <div>
                <b-button block variant="primary">Auswählen</b-button>
              </div>
              </template>
          </b-card>
     </b-card-group>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "../../config";
import api from "../../services/ApiService";
import store from "../../data/store";
import ConditionsService from "../../services/ConditionsService";

/* eslint-disable no-debugger */
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 3,
      title: "Karosserie",
      subline: "Wählen Sie eine Karosserie aus.",
    });
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  mounted() {
    ConditionsService.initialize(this.conditions);
    const route = this.$router;
    const vm = this;

    if (!this.$props.modelId) {
      return api.getMaker(route.currentRoute.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api
          .getModel(maker.id, route.currentRoute.params.model)
          .then((model) => {
            vm.$store.commit("carConfiguration/setCurrentModel", model);
            this.fetchBodyDesigns(model.id);
          });
      });
    } else {
      this.fetchBodyDesigns(this.$props.modelId);
    }
  },
  methods: {
    ...mapActions({
      fetchBodyDesigns: "bodyDesigns/fetchBodyDesigns",
    }),
    imageUriForModel(model) {
      let dbName = "SSCD" in model.photo_path ? "SSCD" : "NSCD";

      return (
        config.apiUri +
        "/ngphotos/" +
        dbName +
        "/" +
        model.photo_path[dbName] +
        "/" +
        model.photo_filename[dbName] +
        ".JPG"
      );
    },
    selectBodyDesign(bodyDesign) {
      const maker = this.$route.params.maker;
      const model = this.$route.params.model;

      this.$store.commit("carConfiguration/setCurrentBodyDesign", bodyDesign);
      this.$router.push({
        name: "trim_levels",
        params: {
          maker: maker,
          model: model,
          bodyDesign: bodyDesign.label,
          bodyDesignId: bodyDesign.id,
        },
      });
    },
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
  },
  computed: {
    ...mapGetters({
      bodyDesigns: "bodyDesigns/bodyDesigns",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },
    computedModelId() {
      return this.$props.modelId || this.fetchedModelId;
    },
  },
  components: {
  },
  data: function () {
    return {
      fetchedModelId: undefined,
    };
  },
  props: ["modelId"],
};
</script>

<style>

</style>
