import api from '../../services/ApiService'
import store from '../store'
import lodash from 'lodash'

const state = {
    models: [],
};

const getters = {
    models() {
        return state.models
    } 
};

const actions = {    
    fetchModels({commit}, makerId) {
        store.dispatch('loading/setLoading', true);
        return api.getModels(makerId).then(models => {
            let sortedModels = lodash.orderBy(models, ['min_price_gross']);
            commit('setModels', sortedModels);
            store.dispatch('loading/setLoading', false);
        });
    }
};

const mutations = {
    setModels(state, models) {
        state.models = models;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};