import api from "../../services/ApiService";
import store from "../store";
import lodash from 'lodash';

const state = {
    bodyDesigns: [],
};

const getters = {
    bodyDesigns() {
        return state.bodyDesigns;
    }
};

const actions = {
    fetchBodyDesigns({ commit }, modelId) {
        store.dispatch("loading/setLoading", true);
        return api
            .getBodyDesigns(modelId)
            .then((bodyDesigns) => {
                let sortedBodyDesigns = lodash.orderBy(bodyDesigns, ['min_price_gross']);

                commit("setBodyDesigns", sortedBodyDesigns);                
                store.dispatch("loading/setLoading", false);
            });
    }
};

const mutations = {
    setBodyDesigns(state, bodyDesigns) {
        state.bodyDesigns = bodyDesigns;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};