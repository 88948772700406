<template>
   <b-list-group-item v-bind:class="iconClass" class="list-group-item">{{value}}</b-list-group-item>
</template>

<script>
export default {
  created() {},
  props: ['iconClass', 'value']
};
</script>

<style>
ul.engine-icon {
  list-style-type:none;
  margin-bottom: 20px;
}
</style>