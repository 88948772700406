import api from '../../services/ApiService'
import store from '../store'

const state = {
    conditions: new Map(),
    engineConditions: new Map(),
    pricing: 'gross'
};

const getters = {
    conditions() {
        return state.conditions;
    },
    engineConditions() {
        return state.engineConditions;
    },
    pricing() {
        return state.pricing;
    }
};

const actions = {
    fetchConditions({ commit }) {
        store.dispatch('loading/setLoading', true);
        return api.getConditions().then(response => {
            let conditions = new Map();

            response.conditions.forEach((c) => {
                conditions.set(`${c.entity}:${c.entity_id}`, c);
            });

            commit('setConditions', conditions);
            store.dispatch('loading/setLoading', false);
        });
    },  
};

const mutations = {
    setConditions(state, conditions) {
        state.conditions = conditions;
    },
    setEngineConditions(state, engineConditions) {
        state.engineConditions = engineConditions;
    },
    setPricing(state, pricing) {
        state.pricing = pricing;
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};