const min = 7427658739644928;
const max = 9007199254740992;
const alphabet = '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ'; // base58
const base = alphabet.length;

function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

function encode(num) {
    var mod, str = '';
    while (num >= base) {
        mod = num % base;
        str = alphabet[mod] + str;
        num = (num - mod) / base;
    }
    return alphabet[num] + str;
}

export default {
    generateUid() {
        return encode(randomInt(min, max));
    }
}