<template>
  <b-row class="cd-sidebar-buttons mt-2">
    <b-button
      @click="navigateToOffer()"
      class="btn cd-button cd-std-button cd-next-button"
      v-if="currentProgress === 7"
      >weiter zur Angebotsanfrage</b-button
    >
    <b-button
      @click="submitContactForm()"
      :class="submitButtonClasses"
      v-if="currentProgress === 8"
      >{{
        offerSubmitted ? "Angebot angefragt!" : "Angebot anfragen"
      }}</b-button
    >
  </b-row>
</template>

<script>
/* eslint-disable no-debugger */
import eventBus from "./../../eventing/eventBus";
import { mapGetters } from "vuex";

export default {
  created() {},
  mounted() {},
  methods: {    
    navigateToOffer() {
      this.$router.push({
        name: "offer",
        params: { configurationId: this.externalId },
      });
    },
    submitContactForm() {
      if (!this.offerSubmitted) {
        eventBus.$emit("submit-contact-form");
      }
    },
  },
  computed: {
    ...mapGetters({
      externalId: "carConfiguration/configurationId",
      currentProgress: "currentProgress",
      offerSubmitted: "offerSubmitted",
    }),
    submitButtonClasses() {
      let classes =
        "btn cd-button cd-std-button cd-next-button";

      if (this.offerSubmitted) {
        classes += " cd-disabled-button";
      }

      return classes;
    },
  },
  props: ["steps", "currentStep"],
  data() {
    return {};
  },
};
</script>

<style>
</style>
