<template>
  <b-container fluid >
    <b-row >
     <b-card-group
        v-bind:key="model.id"
        v-for="model in models"
        class="p-2"
      >
          <b-card
            :img-src="imageUriForModel(model)"
            v-bind:img-alt="model.label"
            @click="selectModel(model)"
            v-bind:title="model.label"
          >
            <b-card-text>
              <div>
                <p class="h3" pill>ab {{ getNewPrice(model['min_price_'+pricing], model) | formatCurrency }}&nbsp;€</p>
                <p class="h6" muted style="text-decoration: line-through">uvp ab {{ model.min_price_gross | formatCurrency }}&nbsp;€</p>
                <br />
              </div>
            </b-card-text>
            <template #footer>
              <div>
                <b-button block variant="primary">Auswählen</b-button>
              </div>
              </template>
          </b-card>
        </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import config from "../../config";
import api from '../../services/ApiService';
import store from '../../data/store';
import ConditionsService from '../../services/ConditionsService';

export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 2,
      title: "Modell",
      subline: "Wählen Sie ein Modell aus.",
    });
    this.$store.commit("setShowCarInfo", true);    
  },
    
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", true);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  /* eslint-disable no-debugger */
  mounted() { 
    ConditionsService.initialize(this.conditions);
    const route = this.$router.currentRoute;
    const vm = this;
    
    if(!this.$props.makerId) {
      return api.getMaker(route.params.maker).then(maker => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        this.fetchModels(maker.id);
      });     
    }else {
      this.fetchModels(this.$props.makerId);
    }
  },
  methods: {
    ...mapActions({
      fetchModels: "models/fetchModels",
    }),
    imageUriForModel(model) {
      let dbName = "SSCD" in model.photo_path ? "SSCD" : "NSCD";

      return (
        config.apiUri +
        "/ngphotos/" +
        dbName +
        "/" +
        model.photo_path[dbName] +
        "/" +
        model.photo_filename[dbName] +
        ".JPG"
      );
    },
    selectModel(model) {
      const maker = this.$route.params.maker;
      this.$store.commit("carConfiguration/setCurrentModel", model);
      this.$router.push({
        name: "body_designs",
        params: { maker: maker, model: model.label, modelId: model.id },
      });
    },
    getNewPrice(oldPrice, data) {
      if(!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    }
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    }
  },
  computed: {
    ...mapGetters({
      models: "models/models",
      conditions: "conditions/conditions",
      makers: "makers/makers",
    }),
    pricing() {
      return config.pricing;
    },
    computedMakerId() {
      return this.$props.makerId || this.fetchedMakerId;
    },
  },
  components: {

  },
  data: function () {
    return {
      fetchedMakerId: "",
    };
  },
  props: ["makerId"],
};
</script>



<style>
</style>
