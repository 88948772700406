import lodash from 'lodash'
import expressionHelper from '../lib/expressionHelper';

export default {
    getPackageContents(extra, extrasSource) {        
        var results = [];

        if (extra.option_type === 'P') {
            var rule = lodash.filter(extra.rules, { kind: 'Inclusion', condition: '' });

            if (rule && rule.length > 0) {
                results = expressionHelper.getExtras(rule[0].rule, extrasSource);
            }
        }

        return results;
    },    
    apply(type, extra, extras) {
        var referencingProperty = (type === 'Inclusion' ? 'includedBy' : (type === 'Exclusion' ? 'excludedBy' : null));

        var rules = lodash.filter(extra.rules, { kind: type });

        if (rules.length > 0) {
            lodash.forEach(rules, function (rule) {
                var meetsCondition = (rule.condition.length === 0) ||
                    expressionHelper.meetsCondition(extras, rule.condition);

                if (meetsCondition) {
                    var items = expressionHelper.getExtras(rule.rule, extras);

                    lodash.forEach(items, function (item) {
                        if (type === 'Inclusion') {
                            item.selected = true;
                        }

                        item.disabled = true;
                        item[referencingProperty] = extra.id;

                        // mark included extras as "included by a package" for later use
                        // i.e. in priceCalculation
                        if (referencingProperty === 'includedBy' && extra.option_type === 'P') {
                            item.includedByPackage = true;
                        }

                        //self.apply(type, item, extras);
                    });
                }
            });
        }
    },
    undo(type, extra, extras) {
        var self = this;

        var referencingProperty = (type === 'Inclusion' ? 'includedBy' : (type === 'Exclusion' ? 'excludedBy' : (type === 'Requirement' ? 'requiredBy' : null)));

        var condition = (type == 'Inclusion' ? { includedBy: extra.id } : (type == 'Exclusion' ? { excludedBy: extra.id } : (type == 'Requirement' ? { requiredBy: extra.id } : null)));
        var items = lodash.filter(extras, condition);

        lodash.forEach(items, function (item) {
            if (type === 'Inclusion' || type === 'Requirement') {
                item.selected = false;
            }
            
            if (type === 'Inclusion' && Object.prototype.hasOwnProperty.call(item, 'includedByPackage')) {
                item.includedByPackage = false;
            }

            item.disabled = false;
            item[referencingProperty] = '';

            self.undo('Requirement', item, extras);
            self.undo('Inclusion', item, extras);
            self.undo('Exclusion', item, extras);
        });
    },
    applyInclusions(extra, extras) {
        this.apply('Inclusion', extra, extras);
    },
    undoInclusions(extra, extras) {
        this.undo('Inclusion', extra, extras);
    },
    applyExclusions(extra, extras) {
        this.apply('Exclusion', extra, extras);
    },
    undoExclusions(extra, extras) {
        this.undo('Exclusion', extra, extras);
    },
    applyNewPrice(extra, extras) {
        var rules = lodash.filter(extra.rules, { kind: 'NewPrice' });
        if (rules.length > 0) {
            lodash.forEach(rules, function (rule) {
                var meetsCondition = (rule.condition.length === 0) ||
                    expressionHelper.meetsCondition(extras, rule.condition);

                if (meetsCondition) {
                    extra.original_price_net = (!Object.hasOwnProperty.call(extra, 'original_price_net')) ? extra.price_net : extra.original_price_net;
                    extra.original_price_gross = (!Object.hasOwnProperty.call(extra, 'original_price_gross')) ? extra.price_gross : extra.original_price_gross;
                    extra.original_leasing_rate_net = (!Object.hasOwnProperty.call(extra, 'original_leasing_rate_net')) ? extra.leasing_rate_net : extra.original_leasing_rate_net;
                    extra.original_leasing_rate_gross = (!Object.hasOwnProperty.call(extra, 'original_leasing_rate_gross')) ? extra.leasing_rate_gross : extra.original_leasing_rate_gross;

                    extra.price_net = rule.price_net;
                    extra.price_gross = rule.price_gross;
                } else {
                    extra.price_net = (Object.hasOwnProperty.call(extra, 'original_price_net')) ? extra.original_price_net : extra.price_net;
                    extra.price_gross = (Object.hasOwnProperty.call(extra, 'original_price_gross')) ? extra.original_price_gross : extra.price_gross;
                    extra.leasing_rate_net = (Object.hasOwnProperty.call(extra, 'original_leasing_rate_net')) ? extra.original_leasing_rate_net : extra.leasing_rate_net;
                    extra.leasing_rate_gross = (Object.hasOwnProperty.call(extra, 'original_leasing_rate_gross')) ? extra.original_leasing_rate_gross : extra.leasing_rate_gross;
                }
            });
        }
    },
    undoRequirements(extra, extras) {
        this.undo('Requirement', extra, extras);
    },
    meetsRequirements(extra, extrasSource) {
        var result = {
            meetsRequirements: true,
            rule: ''
        };

        if (extra.selected) {
            var requirementRules = lodash.filter(extra.rules, { kind: 'Requirement' });

            if (requirementRules.length > 0) {
                lodash.forEach(requirementRules, function (rule) {
                    var meetsCondition = (rule.condition.length === 0) ||
                        expressionHelper.meetsCondition(extrasSource, rule.condition);

                    if (meetsCondition) {
                        result.meetsRequirements = expressionHelper.meetsCondition(extrasSource, rule.rule);
                        result.rule = rule;
                    }
                });
            }
        }

        return result
    },
    validateConfiguration(carConfiguration, extrasSource) {
        var self = this;

        var result = {
            valid: true,
            invalidExtras: [],
            missingColor: false,
            missingTrim: false
        };

        if (extrasSource.length !== 0) {
            lodash.forEach(carConfiguration.extras, function (extra) {
                if (!self.meetsRequirements(extra, extrasSource)) {
                    result.valid = false;
                    result.invalidExtras.push(extra);
                }
            });

            var chosenColor = lodash.filter(carConfiguration.extras, { category_name: 'Farben', selected: true })[0];
            if (!chosenColor) {
                result.valid = false;
                result.missingColor = true;
            }
        }

        return result;
    }
}