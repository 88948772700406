const state = {
    engine: {},
    currentAction: "",
    extraPrice: 0,
};

const getters = {
    engine() {
        return state.selectedEngine
    },
    currentAction() {
        return state.currentAction
    },
    extraPrice() {
        return state.extraPrice
    }
};

const actions = {
    setEngine({commit}, status) {
        commit('setEngine', status)
    },
    setCurrentAction({commit}, status) {
        commit('setCurrentAction', status)
    },
    setExtraPrice({commit}, status) {
        commit('setExtraPrice', status)
    }
};

const mutations = {
    setEngine(state, status) {
        state.engine = status
    },
    setCurrentAction(state, status) {
        state.currentAction = status
    },
    setExtraPrice(state, status) {
        if (typeof status == "string") state.extraPrice = 0
        else state.extraPrice = status
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};