<template>
    <b-container id="alles-auto" fluid class="d-flex flex-column vh-100">
        <div class="header-container">
          <div align-h="center" class="pt-2 pb-2">
            <b-col class="p-0">
              <h2>Konfiguration</h2>
            </b-col> 
          </div>
        </div>
        <b-row class="progress-bar-container mt-40">
          <b-col class="d-flex flex-row">
            <b-col class="p-0">
                <p class="h5 d-flex flex-row align-items-center" v-if="progress=='-1. '" @click="goBack()">
                  <span class="text-white check-container mr-2"><font-awesome-icon icon="check" /></span>
                  Motor
                </p>
                <p class="h5" v-else>{{progress}}</p>
            </b-col>
            <b-col class="p-0">
              <p class="h5 disabled-color">{{secondProgress}}</p>
            </b-col>
          </b-col>
        </b-row>
        <router-view />
        <!--<div class="compare-container">
          <b-row>
            <b-col
                class="equipment-compairsion-link"
                v-if="showEquipmentCompairsionLink"
              >
              <b-link v-b-modal.modal-1 @click.prevent="openEquipmentCompairsion()" variant="outline-secondary">Ausstattungen vergleichen</b-link>
            </b-col>
          </b-row>
        </div>-->
      
      <div class="footer fixed-bottom">
        <b-row class="h-100 m-0">
            <b-col class="h-100 col-9 left d-flex align-items-center">
              <div class="pl-4">
                <span>UVP</span>
                <p class="h3">{{ getMinPrice | formatCurrency}}€</p>
              </div>
            </b-col>
            <b-col :class="['h-100 col-3 right d-flex align-items-center justify-content-center footer-next-button', action != 'SetEngine' ? 'enable' : (hasSelectedColor() ? 'enable' : 'disable')]" @click.prevent="nextAction()">
              <div>
                Weiter
              </div>
            </b-col>
          </b-row>

    </div>
  </b-container>
</template>

<script>
import AuthService from "./services/AuthService";
import { mapGetters, mapActions} from "vuex";
//import Loader from "./components/utils/Loader.vue";
import eventBus from "./eventing/eventBus";
import ApiService from "./services/ApiService";
import ConditionsService from "./services/ConditionsService";
import store from "./data/store";
import { PriceCalculation, createCarConfigurationObject } from './services/PriceCalculationService';
import lodash from "lodash";

/* NEW CD Systeme Konfigurator | import CarInfo from "./components/elements/CarInfo.vue"; */

export default {
  name: "App",
  created() {
    const accessToken = this.$root.$el.dataset["auth"];
    const privacyPolicy = this.$root.$el.dataset["privacypolicy"];    

    this.$store.commit("auth/accessToken", accessToken);
    this.$store.commit("setPrivacyPolicyUrl", privacyPolicy);

    const predefinedMake = this.$root.$el.dataset["make"];
    const predefinedModel = this.$root.$el.dataset["model"];
    const predefinedBodyDesign = this.$root.$el.dataset["bodydesign"];
    const predefinedTrimLevel = this.$root.$el.dataset["trim"];

    this.$store.commit("setPredefinedMake", predefinedMake);
    this.$store.commit("setPredefinedModel", predefinedModel);
    this.$store.commit("setPredefinedBodyDesign", predefinedBodyDesign);
    this.$store.commit("setPredefinedTrimLevel", predefinedTrimLevel);

  },
  beforeDestroy() {

  },
  mounted() {
    const vm = this;
    const router = this.$router;
    
    AuthService.authenticate().then((res) => {
      if (res) {
        const tenant = res.user;
        this.$store.commit("auth/tenant", tenant);
        

        vm.fetchConditions().then(() => {        
          if (router.history.current.fullPath === "/") {            
            router.push({ name: "makers" });
          }
        });

        if(router.history.current.name === null || router.history.current.name === 'makers') {
          if(vm.predefinedMake) {

            if(vm.predefinedModel) {

              if(vm.predefinedBodyDesign) {                  

                if(vm.predefinedTrimLevel) {
                  router.push({ name: "engines",  params: { maker: vm.predefinedMake, model: vm.predefinedModel, bodyDesign: vm.predefinedBodyDesign, trimLevel: vm.predefinedTrimLevel}});
                  return;
                }

                router.push({ name: "trim_levels",  params: { maker: vm.predefinedMake, model: vm.predefinedModel, bodyDesign: vm.predefinedBodyDesign}});
                return;
              }

              router.push({ name: "body_designs",  params: { maker: vm.predefinedMake, model: vm.predefinedModel }});
              return;
            }              

            router.push({ name: "models",  params: { maker: vm.predefinedMake }});  
            return;
            }
        }

        if (router.history.current.fullPath === "/") {
          router.push({ name: "makers" });
        }

        
      }
    });
  },
  methods: {
    ...mapActions({
      fetchConditions: 'conditions/fetchConditions',
      saveConfiguration: "carConfiguration/saveConfiguration",
    }),
    goBack() {
      this.$router.go(-1)
    },
    hasSelectedColor() {
      var chosenColor = lodash.filter(this.equipment, {category_name: 'Farben', selected: true})[0];
      return chosenColor != null;
    },
    openEquipmentCompairsion() {
      eventBus.$emit("open-equipment-compairsion");
    },
    nextAction() {
      const engine = this.engine;
      let vm = this;
      this.$store.commit("setSecondProgress", "2. Ausstattung");
      if (this.action == "SetEngine") {
        if (!this.hasSelectedColor()) {
          return;
          // this.$bvModal.show("cd-equipment-modal");
        }
        store.dispatch("loading/setLoading", true);

        this.equipment.forEach((extra) => {
          extra.price_gross = extra.price_gross ? extra.price_gross : 0;
          extra.price_net = extra.price_net ? extra.price_net : 0;
        });

        vm.$store.commit(
          "carConfiguration/setCurrentExtras",
          this.equipment
        );
        
        let calcObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
        let result = PriceCalculation.calculateDiscountedPrice(calcObj);

        vm.$store.commit('carConfiguration/setDiscountedPrice', result);
        console.log('here')
        vm.saveConfiguration().then(() => {
          window.dispatchEvent(new CustomEvent("CONFIGURATION_FINISHED", {
            detail: {
              configuration_id: vm.configurationId
            }
          }));
        
          store.dispatch("loading/setLoading", false);
          vm.$router.push({
            name: "summary",
            params: { configurationId: this.configurationId },
          });
        });
        return
      }
      this.$store.commit("carConfiguration/setCurrentEngine", engine);
      this.$store.commit(
        "carConfiguration/setCurrentCondition",
        ConditionsService.getConditions(engine)
      );
      this.$store.commit("equipment/setEquipment", []);
      this.$store.commit("setCurrentExtras/currentExtras", []);
      ApiService.getStandardEquipments(engine.id).then((equipments) => {
        vm.$store.commit("carConfiguration/setStandardEquipments", equipments);
        this.$router.push({
          name: "equipment",
          params: {
            maker: this.currentMaker.label,
            model: this.currentModel.label,
            bodyDesign: this.currentBodyDesign.label,
            trimLevel: this.currentTrimLevel.label,
            engine: engine.label,
            engineId: engine.id,
          },
        });
      });
    }
  },
  computed: {
    getMinPrice() {
      //debugger; // eslint-disable-line no-debugger
      if(!this.engine) {
        let engineList = [...this.engines];
        let sorted = engineList.sort((a,b) => a.min_price_gross - b.min_price_gross);
        
        return sorted[0] ? sorted[0].min_price_gross : 0;
      }
      return (this.currentModel && (this.currentModel.min_price_gross + this.extraPrice))
    },
    ...mapGetters({
      engines: "engines/engines",
      secondProgress: "secondProgress",
      progress: "progress",
      currentProgress: "currentProgress",
      maxProgress: "maxProgress",
      progressSubline: "progressSubline",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      currentTrimLevel: "carConfiguration/currentTrimLevel",
      configurationId: "carConfiguration/configurationId",
      engine: "carConfiguration/currentEngine",
      showEquipmentCompairsionLink: "showEquipmentCompairsionLink",
      action: "nextActions/currentAction",
      extraPrice: "nextActions/extraPrice",
 /* NEW CD Systeme Konfigurator |      showCarInfo: "showCarInfo", */
      showSidebar: "showSidebar",
      equipment: "equipment/equipment",
      predefinedMake: 'predefinedMake',
      predefinedModel: 'predefinedModel',
      predefinedBodyDesign: 'predefinedBodyDesign',
      predefinedTrimLevel: 'predefinedTrimLevel',
    }),
    /* eslint-disable no-debugger */
    isCompactLayout() {
    
      return this.currentProgress > 6;
    },
    currentSelection() {
      let selectionText = "";

      if (this.currentMaker) {
        selectionText += this.currentMaker.label;
      }

      if (this.currentModel) {
        selectionText += ", " + this.currentModel.label;
      }

      if (this.currentBodyDesign) {
        selectionText += ", " + this.currentBodyDesign.label;
      }

      if (this.currentTrimLevel) {
        selectionText += ", " + this.currentTrimLevel.label;
      }

      return selectionText;
    }, 
  },
  
  components: {
    //Loader,
/* NEW CD Systeme Konfigurator |     CarInfo, */
  }
};
</script>

<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue.css"></style>
<style scoped src="bootstrap-icons/font/bootstrap-icons.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue-icons.css"></style>
<style scoped src="vue-select/dist/vue-select.css"></style>

<style>

@font-face {
  font-family: "GintoNormal-Regular";
  src: local("GintoNormal-Regular"),
   url(https://d1r1ppqc6t030u.cloudfront.net/assets/themes/aa/ABCGintoNormal-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "GintoNormal-Bold";
  src: local("GintoNormal-Bold"),
   url(https://d1r1ppqc6t030u.cloudfront.net/assets/themes/aa/ABCGintoNormal-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "GintoNord-Bold";
  src: local("GintoNord-Bold"),
   url(https://d1r1ppqc6t030u.cloudfront.net/assets/themes/aa/ABCGintoNord-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "bootstrap-icons";
  src: local("bootstrap-icons"),
  url(./assets/fonts/c-bootstrap-icons.woff) format("woff"),
  url(./assets/fonts/c-bootstrap-icons.woff2) format("woff2"),
}

.check-container {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1ae6b9;
  border-radius: 7px;
  font-size: 10px;
}

#alles-auto {
  padding-top: 80px !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
  overflow-y: auto;
}

#alles-auto .layout-padding {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

#alles-auto .mt-40 {
  margin-top: 40px;
}

#alles-auto h1,
#alles-auto h2,
#alles-auto h3 {
    font-family: 'GintoNord-Bold', sans-serif !important;
    font-weight: bold;
}

#alles-auto {
  font-family: 'GintoNormal-Regular', sans-serif !important;
}


/* START Makers */

  .img-abarth {
  background: url("./assets/images/maker/abarth.svg") no-repeat;
}  

  .img-aston-martin {
  background: url("./assets/images/maker/astonMartin.svg") no-repeat;
}

.img-bentley {
  background: url("./assets/images/maker/bentley.svg") no-repeat;
}

.img-cupra {
  background: url("./assets/images/maker/cupra.svg") no-repeat;
}

.img-bmw {
  background: url("./assets/images/maker/bmw.svg") no-repeat;
}

.img-bmwi {
  background: url("./assets/images/maker/bmwi.svg") no-repeat;
}

.img-fiat {
  background: url("./assets/images/maker/fiat.svg") no-repeat;
}

.img-ford {
  background: url("./assets/images/maker/ford.svg") no-repeat;
}

.img-hyundai {
  background: url("./assets/images/maker/hyundai.svg") no-repeat;
}

.img-hyundai {
  background: url("./assets/images/maker/hyundai.svg") no-repeat;
}

.img-jaguar {
  background: url("./assets/images/maker/jaguar.svg") no-repeat;
}

.img-kumpan {
  background: url("./assets/images/maker/kumpan.svg") no-repeat;
}

.img-land-rover {
  background: url("./assets/images/maker/landrover.svg") no-repeat;
}

.img-lexus {
  background: url("./assets/images/maker/lexus.svg") no-repeat;
}

.img-mazda {
  background: url("./assets/images/maker/mazda.svg") no-repeat;
}

.img-mercedes-benz {
  background: url("./assets/images/maker/mercedes.svg") no-repeat;
}

.img-mini {
  background: url("./assets/images/maker/mini.svg") no-repeat;
}

.img-mitsubishi {
  background: url("./assets/images/maker/mitsubishi.svg") no-repeat;
}

.img-opel {
  background: url("./assets/images/maker/opel.svg") no-repeat;
}

.img-polestar {
  background: url("./assets/images/maker/polestar.svg") no-repeat;
}

.img-seat {
  background: url("./assets/images/maker/seat.svg") no-repeat;
}

.img-skoda {
  background: url("./assets/images/maker/skoda.svg") no-repeat;
}

.img-smart {
  background: url("./assets/images/maker/smart.svg") no-repeat;
}

.img-subaru {
  background: url("./assets/images/maker/subaru.svg") no-repeat;
}

.img-toyota {
  background: url("./assets/images/maker/toyota.svg") no-repeat;
}

.img-volkswagen {
  background: url("./assets/images/maker/volkswagen.svg") no-repeat;
}

.img-volvo {
  background: url("./assets/images/maker/volvo.svg") no-repeat;
}

.img-citroën {
  background: url("./assets/images/maker/citroen.svg") no-repeat;
}

.img-peugeot {
  background: url("./assets/images/maker/peugeot.svg") no-repeat;
}

/* END Makers */

/* GLOBAL */

.maker-image {
  background-position: 50% 50% !important;
  background-size: 4em !important;
  height: 100%;
}

.card {
  min-height: 10em;
  min-width: 16em !important;  
  max-width: 20em;
}

.card-footer {
  text-align: center;
}

.card:hover {
  background-color: #F7F7F7;
}

.card ul {
  list-style-type: square;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.card-deck .card {
  margin-bottom: 30px;
}

/*Icons for Trimlevel Modal*/
.check-icon {
  background: url("./assets/images/check.svg") no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

ul.engine-icon {
  -webkit-padding-start: 0px;
  padding-inline-start: 0px;
}

[class^="engine-icon"] {
    padding-left: 36px;
    fill: #fff;
}

/*Icons for Engines*/

.list-group:hover,
.list-group-item:hover {
    background-color: unset !important}

.list-group-item {
  background-color: unset !important;
  border: 0px!important;
  padding-left: 24px!important;
  align-items: center;
}

/* Alles Auto */

:root {
  --alles-auto-primary: #680075;
  --alles-auto-secondary: #6F6F6F;
}

#alles-auto .list-group-item {
    color:  var(--alles-auto-secondary);
    /*justify-content: center;*/
}

#alles-auto .custom-control h3 {
  font-weight: 700;
}

#alles-auto .custom-control-label,
#alles-auto .h3 {
  color: var(--alles-auto-primary);
}

#alles-auto p.h5 {
  color: var(--alles-auto-primary);
}


#alles-auto .engine-icon-meter {
  background: url("./assets/images/meter.svg") no-repeat;
  background-position: 0 50%;
}

#alles-auto .engine-icon-branch {
  background: url("./assets/images/branch.svg") no-repeat;
  background-position: 0 50%;
}

#alles-auto .engine-icon-rain-drop {
  background: url("./assets/images/rain-drop.svg") no-repeat;
  background-position: 0 50%;
}

#alles-auto .engine-icon-carbon-accounting {
  background: url("./assets/images/carbon-accounting.svg") no-repeat;
  background-position: 0 50%;
}

#alles-auto .engine-icon-tag {
  background: url("./assets/images/tag.svg") no-repeat;
  background-position: 0 50%;
}

#alles-auto .footer {
  height: 100px;
}

#alles-auto .footer .left {
  background-color: #680075;
}

#alles-auto .footer .left span {
  color: #fff;
}

#alles-auto .footer .left p.h3 {
  color: #1DFFCD;
  font-family: 'GintoNord-Bold', sans-serif !important;

}

#alles-auto .footer .right {
  background-color: #FF195A;  
  color: #fff;
}

/* Alles Auto  E N D*/



.engine-icon-motor-power {
  background: url("./assets/images/motor_power.svg") no-repeat;
  background-position: 0 50%;
}

.engine-icon-fuel-type {
  background: url("./assets/images/fuel.svg") no-repeat;
  background-position: 0 50%;
}

.engine-icon-transmission {
  background: url("./assets/images/transmission.svg") no-repeat;
  background-position: 0 50%;
}

.engine-icon-fuel-consumption {
  background: url("./assets/images/fuel_consumption.svg") no-repeat;
  background-position: 0 50%;
}

.engine-icon-emission {
  background: url("./assets/images/emission.svg") no-repeat;
  background-position: 0 50%;
}

.primary-button {
  background-color: var(--alles-auto-primary);
  border-radius: 4px;
  height: 40px;
  padding: 10px;
  outline: none;
  border:none;
  color: white;
}

.primary-button:disabled {
  cursor:not-allowed;
}

.primary-button:hover {
  opacity: 0.7;
}

.bold {
  font-weight: 700 !important;
}

.purple-radio {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 22px;
  height: 22px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid var(--alles-auto-primary);
  border-radius: 50%;
  padding: 3px !important;
}

/* appearance for checked radiobutton */
.purple-radio:checked {
  background-color: var(--alles-auto-primary);
}

input[type="radio"]:checked {
  background-color: var(--alles-auto-primary);
}


[type="checkbox"] {
  position: relative;
  left: 15px;
  top: -4px;
  z-index: 0;
  -webkit-appearance: none;
}
[type="checkbox"] + label {
  position: absolute;
}
[type="checkbox"] + label::before {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 2px solid #8cad2d;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  z-index: 5;
  position: relative;
}
[type="checkbox"]:checked+label::before {
  box-shadow: inset 0px 0px 0px 3px #fff;
  background-color: #8cad2d;}







.purple-checkbox {
  width: 22px;
  height: 22px;
  cursor: pointer;
  font-size: 17px;
  visibility: visible;
}

.purple-checkbox:after,
.purple-checkbox::after {
  content: " ";
  background-color: #fff;
  display: inline-block;
  color: var(--alles-auto-primary);
  width: 22px;
  height: 22px;
  visibility: visible;
  border: 1px solid var(--alles-auto-primary);
  padding-left: 3px;
  border-radius: 5px;
}

.purple-checkbox:checked:after {
  content: "\2714";
  font-weight: bold;
}

.disabled-color {
  color: #a8a8a8 !important;
}

.footer-next-button {
  font-family: 'GintoNormal-Bold', sans-serif !important;
  cursor: pointer;
}

.collapse {
  visibility: inherit;
}

@media screen and (max-width: 700px) {
  #alles-auto {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 30px !important;
  }  

  .layout-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }  
}

.footer-next-button.disable {
  background-color: #c6c6c6 !important;
  padding: 40px !important;
  gap: 10px !important;
  cursor: not-allowed;
  font-family: 'GintoNormal-Bold', sans-serif !important;

}



</style>
