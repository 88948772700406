<template>
  <b-container fluid class="engines d-flex vh-100 p-0 m-0 flex-column">
    <b-row class="d-flex w-100 m-0">
      <b-col class="p-0">
        <b-col class="progress-bar-container pl-0">
          <div class="progress">
            <div class="progress-bar sticky-header" role="progressbar" style="width: 30%;" aria-valuenow="30" aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </b-col>

        <b-col class="engines-container p-0 p-0">
          <div class="row engine-category-selector p-0" >
            <div class="col">
              <div class="selector-title">Sortieren nach:</div>
              <select @change="onSortByChange($event)">
                <option value="min_price_gross">Preis</option>
                <option value="label">Verbrauch</option>
              </select>
            </div>
            <div class="col">
              <div class="selector-title">Gruppieren nach:</div>
              <select @change="onGroupByChange($event)">
                <option value="fuel_type">Kraftstoff</option>
                <option value="drive">Antrieb</option>
                <option value="transmission">Getriebe</option>
              </select>
            </div>
          </div>
          <div class="col accordion custom-radio py-4" v-for="category in categorizedEngines" v-bind:key="category.category"
            v-bind:id="category.category">

            <div>
              <span class="engine-accordion-title">{{ category.category }}</span>
            </div>

            <div class="card engine-accordion" v-for="engine in category.engines" v-bind:key="engine.id">

              <div class="engine-option" :id="'card-header-' + engine.id">
                <div class="mb-0 w-100">
                  <button class="btn btn-link w-100 p-0 text-decoration-none-hover" v-b-toggle="'collapse-' + engine.id" @click="setEngine(engine)" >
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <input type="radio" class="engine-option-input purple-radio" :id="'radio-' + engine.id"
                          name="engine-options">
                        <div class="w-100" :for="'radio-' + engine.id" @click="'collapse-' + engine.id">{{ engine.label }}
                        </div>
                      </div>
                      <div>
                        <div class="bold">
                          ab {{ getNewPrice(engine["min_price_" + pricing], engine) | formatCurrency }}&nbsp;€
                        </div>
                      </div>
                    </div>

                  </button>
                </div>
              </div>

              <b-collapse :id="'collapse-' + engine.id">
                <div class="engine-accordion-body">
                  <b-list-group horizontal class="engine-icon flex flex-wrap">
                    <IconLabel iconClass="engine-icon-meter"
                      :value="engine.technical_data.max_power_kw + 'kW (' + engine.technical_data.max_power_ps + ' PS)'" />
                    <IconLabel iconClass="engine-icon-branch" :value="engine.transmission" />
                    <!--<IconLabel iconClass="engine-icon-fuel-type" :value="engine.fuel_type"/>-->
                    <IconLabel
                      v-if="engine.fuel_type != 'Elektro' || (engine.fuel_type === 'Elektro' && (getCo2(engine) != '-' && getCo2(engine) != '0'))"
                      iconClass="engine-icon-rain-drop"
                      :value="(engine.fuel_type == 'Wasserstoff' || engine.fuel_type == 'Erdgas') ? getGasConsumption(engine) + 'kg/100km (komb.)' : getFuelConsumption(engine) + 'l/100km (komb.)'" />
                    <IconLabel v-if="engine.fuel_type == 'Elektro'" iconClass="engine-icon-rain-drop"
                      :value="getPowerConsumption(engine) + 'kWh/100km'" />
                    <IconLabel iconClass="engine-icon-carbon-accounting" :value="getCo2(engine) + 'g/km'" />
                    <IconLabel iconClass="engine-icon-tag" :value="engine.technical_data.energy_efficiency"
                      :id="'energyefficiency-target-' + engine.id">
                      <b-popover :target="'energyefficiency-target-' + engine.id" triggers="hover">
                        <EnergyEfficiencyBox :energyClass="engine.technical_data.energy_efficiency" />
                      </b-popover>
                    </IconLabel>
                    <b-popover :target="'engine-target-' + engine.id" triggers="hover">
                      <EnergyEfficiencyBox :energyClass="engine.technical_data.energy_efficiency" />
                    </b-popover>
                  </b-list-group>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-col>
    </b-row>
    <b-row class="legend-container row w-100">
      <b-col class="w-100">
        <span><strong>Legende</strong></span>
        <ul class="horizontal d-flex flex-wrap p-0 legend-list">
          <li class="list-group-item engine-icon-meter">Leistung</li>
          <li class="list-group-item engine-icon-branch">Getriebe</li>
          <li class="list-group-item engine-icon-rain-drop">Verbrauch (komb.)</li>
          <li class="list-group-item engine-icon-carbon-accounting">CO2-Emission (kombiniert)</li>
          <li class="list-group-item engine-icon-tag">Energieeffizienz&shy;klasse</li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "../../services/ApiService";
import { mapActions, mapGetters } from "vuex";
import IconLabel from "../elements/IconLabel";
//import StylizedRadioGroup from "../elements/StyledRadioGroup.vue";
import EnergyEfficiencyBox from "../elements/EnergyEfficiencyBox.vue";
import store from "../../data/store";
import ConditionsService from "../../services/ConditionsService";
import config from "../../config";
import valueService from '../../services/ValueService';

export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 1,
      title: "Motor",
      subline: "Wählen Sie einen Motor aus.",
    });
    this.$store.commit("setSecondProgress", "2. Ausstattung");
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  beforeRouteLeave (to, from , next) {
    this.$store.commit("setSecondProgress", "");
    next()
  }, 
  mounted() {
    ConditionsService.initialize(this.conditions);

    this.$store.commit("setShowCarInfo", false);
    const vm = this;

    if (!this.$props.trimLevelId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return api
                .getTrimLevel(bodyDesign.id, route.params.trimLevel)
                .then((trimLevel) => {
                  vm.$store.commit(
                    "carConfiguration/setCurrentTrimLevel",
                    trimLevel
                  );
                  vm.fetchEngines(trimLevel.id).then(() => {
                    vm.categorizeEnginesBy(vm.selectedEngineFilter);
                  });
                });
            });
        });
      });
    } else {
      vm.fetchEngines(this.$props.trimLevelId).then(() => {
        vm.categorizeEnginesBy(vm.selectedEngineFilter);
      });
    }
  },
  beforeUnmount() {
  },
  computed: {
    ...mapGetters({
      engines: "engines/engines",
      categorizedEngines: "engines/filteredEngines",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      currentTrimLevel: "carConfiguration/currentTrimLevel",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },
  },
  methods: {
    ...mapActions({
      fetchEngines: "engines/fetchEngines",
      categorizeEnginesBy: "engines/categorizeEnginesBy",
      sortEnginesBy: "engines/sortEnginesBy",
    }),
    onGroupByChange(event) {
      this.selectedEngineFilter = event.target.value;
    },
    onSortByChange(event) {
      this.sortEnginesBy(event.target.value)
    },
    getCo2(engine) {
      return valueService.getCo2(engine);
    },
    getFuelConsumption(engine) {
      return valueService.getConsumption(engine);
    },
    getPowerConsumption(engine) {
      return valueService.getPowerConsumption(engine);
    },
    getGasConsumption(engine) {
      return valueService.getGasConsumption(engine);
    },
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
    calculateEnergyClass(energyClass) {
      if (!energyClass) {
        return "-";
      }
      let c = energyClass.toLowerCase();

      let cssClass = "efficiency-";
      c = c.replace(/\+/g, "p");

      return cssClass + c;
    },
    setEngine(engine) {
      document.getElementById("radio-"+engine.id).checked = true
      this.selectedEngine = engine;
      this.$store.commit("carConfiguration/setCurrentEngine", engine);
      this.$store.commit("carConfiguration/setEnginePrice", engine.min_price_gross);
    },
    selectEngine() {
      
    },
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
    selectedEngineFilter() {
      this.categorizeEnginesBy(this.selectedEngineFilter);
    },
  },
  props: ["trimLevelId"],
  data() {
    return {
      engineFilters: [
        { text: "Kraftstoff", value: "fuel_type" },
        { text: "Antrieb", value: "drive" },
        { text: "Getriebe", value: "transmission" },
      ],
      selectedEngineFilter: "fuel_type",
      selectedSortByField: "min_price_gross",
      showEnergyEficiencyPopup: false,
      currentEnergyEficiencyClass: "",
      selectedEngine: null,
      previousPrice: 0
    };
  },
  components: {
    IconLabel,
    EnergyEfficiencyBox,
  },
};
</script>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue.css"></style>
<style scoped src="bootstrap-icons/font/bootstrap-icons.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue-icons.css"></style>

<style>

/* Alles Auto */

div.engines {
  overflow: auto;
}


div.legend-container {
  padding-bottom: 100px !important;
    
}

#alles-auto .accordion .btn-link {
  color: var(--alles-auto-primary);
}

#alles-auto .accordion .card,
#alles-auto .accordion .card-header {
  background-color: #fff !important;
  border: none;
}

icon label{
  padding-left: 8px;
}

div.list-group-item {
  min-width: 12em !important;
  justify-content: center;
  display: flex;
}

.engine-category {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.columns-right {
  text-align: right;
  padding: 20px;
}

[class^="engine-icon"] {
  padding-left: 30px;
  fill: #fff;
}

.engine-representation .card {
  background-color: #f9f9f9;
  border: none;
  padding: 0;
  border-radius: 15px;
  margin-bottom: 25px;
}

.progress {
  height: .5rem !important;
  margin-bottom: 26px;
}

.progress .progress-bar {
  background-color: #1AE6B9 !important;
}

.legend-list li {
  flex: 0 0 33.333333%;
}

.engine-option-input {
  min-width: 22px;
  min-height: 22px;
  border-radius: 22px;
  margin-right: 15px !important;
}

.engine-option {
  font-size: 16px;
  font-weight: 400;
}

span.engine-accordion-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 27px;
}

.engine-accordion {
  padding: 15px 0;
}

.engine-accordion-body {
  margin-left: 37px;
  margin-top: 15px;
}

.engine-accordion-body .list-group-item {
  flex: 0 0 33.333333%;
}

.engine-category-selector select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  border-radius: 4px;
  height:50px;
  padding: 15px 20px;
  border: 1px solid var(--alles-auto-primary);
  color: var(--alles-auto-primary);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 15px;
  padding-right: 40px !important;
}

.engine-category-selector {
  display: flex;
  justify-content: space-between;
}

.engine-category-selector>div {
  display: flex;
  align-items: center;
}

select:focus, select:active {
  outline: none !important;
}

.selector-title {
  margin-right: 10px;
  font-size: 16px;
}

.text-decoration-none-hover {
  text-decoration: none !important;
}

</style>

