import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import store from './data/store';
import axios  from 'axios';
import { onSuccess, onError, beforeRequestSuccess, beforeRequestError } from './interceptors/jwt';
import router from './routes/router'


/* fontawesome START*/
 
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone,faPlus, faCircleInfo, faMinus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhone);
library.add(faPlus);
library.add(faMinus);
library.add(faCircleInfo)
library.add(faCheck)
Vue.component("font-awesome-icon", FontAwesomeIcon, IconsPlugin);

/* fontawesome END*/


import vSelect from 'vue-select'
import Vuelidate from 'vuelidate'
import AsyncComputed from 'vue-async-computed'

/* 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'vue-select/dist/vue-select.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
*/

axios.interceptors.request.use(beforeRequestSuccess, beforeRequestError);
axios.interceptors.response.use(onSuccess, onError);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(AsyncComputed)


Vue.component('v-select', vSelect)

Vue.config.productionTip = false

Vue.filter('formatCurrency', function(value, precision) {    
  if(!value) return '';

  if(!precision) {
      precision = 2;
  }

  var val = (value/1).toFixed(0).replace('.', ',')

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
});


new Vue({
  render: h => h(App),  
  router,
  store,  
  components: {App},
  template: 'Hello',


}).$mount('#cd-configurator');