<template>
  <div class="energy-efficiency-box">
    <span
      :class="
        'energy-efficiency-marker '+markerClass
      "
      >{{ energyEfficiencyClass }}</span
    >
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      energyEfficiencyClass: this.$props.energyClass,
    };
  },
  computed: {
      markerClass() {
          let markerClass = this.energyEfficiencyClass.toLowerCase();
          if(this.energyEfficiencyClass === 'a+') {
              markerClass = 'ap';
          }

          return 'energy-efficiency-' + markerClass;
      }
  },
  props: ["energyClass"],
};
</script>

<style>
.popover {
  max-width: 320px!important;
}

.efficiency-mark {
    width: 41px;
    height: 21px;
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    color: #fff;
    text-align: center;
    margin-left: 5px;}

.energy-efficiency-box {
  display: block;
  position: relative;
  background: url("./../../assets/images/energieeffizienzklasse.png") no-repeat;
  height: 230px;
  width: 270px;
}

.energy-efficiency-marker {
  position: absolute;
  right: 0;
  background: url("./../../assets/images/energy_marker.svg") no-repeat;
  filter: invert(18%) sepia(7%) saturate(157%) hue-rotate(169deg)
    brightness(97%) contrast(88%);
  width: 43px;
  color: #fff;
  text-align: center;
  height: 20px;
}


.efficiency-mark .mark-background,
.efficiency-marklabel {
    left: 0;
    top: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
}

.mark-background {
    background: url("./../../assets/images/energy_marker.svg") no-repeat;
}

.efficiency-mark .efficiency-appp{filter:invert(35%) sepia(80%) saturate(2677%) hue-rotate(133deg) brightness(96%) contrast(104%)}
.efficiency-mark .efficiency-ap{filter:invert(49%) sepia(99%) saturate(308%) hue-rotate(67deg) brightness(103%) contrast(91%)}
.efficiency-mark .efficiency-a,
.efficiency-mark .efficiency-b{filter:invert(99%) sepia(40%) saturate(2853%) hue-rotate(10deg) brightness(89%) contrast(86%)}
.efficiency-mark .efficiency-c{filter:invert(99%) sepia(31%) saturate(5919%) hue-rotate(346deg) brightness(100%) contrast(105%);}
.efficiency-mark .efficiency-d{filter:invert(55%) sepia(82%) saturate(2545%) hue-rotate(345deg) brightness(96%) contrast(97%)}
.efficiency-mark .efficiency-e{filter:invert(12%) sepia(97%) saturate(5764%) hue-rotate(353deg) brightness(99%) contrast(88%)}
.efficiency-mark .efficiency-f{filter:invert(24%) sepia(80%) saturate(4754%) hue-rotate(345deg) brightness(90%) contrast(107%)}
.efficiency-mark .efficiency-g{filter:invert(22%) sepia(99%) saturate(6390%) hue-rotate(351deg) brightness(96%) contrast(93%)}

.energy-efficiency-appp {
  top: 0px;
}
.energy-efficiency-app {
  top: 26px;
}
.energy-efficiency-ap {
    top: 46px;
}
.energy-efficiency-a {
    top: 70px;
}
.energy-efficiency-b {
    top: 92px;
}
.energy-efficiency-c {
    top: 112px;
}
.energy-efficiency-d {
    top: 136px;
}
.energy-efficiency-e {
    top: 157px;
}
.energy-efficiency-f {
    top: 180px;
}
.energy-efficiency-g {
    top: 204px;
}
</style>