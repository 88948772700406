const state = {    
    tenant: null,
    accessToken: ''
};


const getters = {
    accessToken() {
        return state.accessToken;
    },    
    tenant() {
        return state.tenant
    },
    authenticated() {
        return state.accessToken && state.tenant;
    }
};

const actions = {}

const mutations = {
    accessToken(state, token) {
        state.accessToken = token;
    },
    tenant(state, tenant) {
        state.tenant = tenant;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}