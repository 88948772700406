import lodash from 'lodash'

let Price = function() {
    this.net = 0;
    this.gross = 0;
}


let PriceCalculation = {
    calculateBasePrice(carConfiguration) {
        var result = new Price();
      
        if (carConfiguration.engine) {
          result.net = result.net + carConfiguration.engine.min_price_net;
          result.gross = result.gross + carConfiguration.engine.min_price_gross;
        } else if (carConfiguration.trimLevel) {
          result.net = result.net + carConfiguration.trimLevel.min_price_net;
          result.gross = result.gross + carConfiguration.trimLevel.min_price_gross;
        } else if (carConfiguration.bodyDesign) {
          result.net = result.net + carConfiguration.bodyDesign.min_price_net;
          result.gross = result.gross + carConfiguration.bodyDesign.min_price_gross;
        } else if (carConfiguration.model) {
          result.net = result.net + carConfiguration.model.min_price_net;
          result.gross = result.gross + carConfiguration.model.min_price_gross;
        }

        if(carConfiguration.conditions != null && carConfiguration.conditions.leasing_factor != null && carConfiguration.conditions.leasing_factor > 0){
          carConfiguration.leasingFactor = carConfiguration.conditions.leasing_factor;
        }
      
        if (carConfiguration.model && carConfiguration.leasingFactor) {
          result.leasingRateNet = result.net * (carConfiguration.leasingFactor / 100);
          result.leasingRateGross = result.gross * (carConfiguration.leasingFactor / 100);
        }
      
        return result;
      },
      calculateTotalPrice(carConfiguration) {
        var result = this.calculateBasePrice(carConfiguration);
        var chosenExtras = lodash.filter(carConfiguration.extras, {selected: true});
      
        lodash.forEach(chosenExtras, extra => {
          var calculatePriceForCurrentExtra = true;
      
          // determine if this extra is added by an include rule. if so, we will not
          // calculate the prices for this extra, since the include rule defines, that 
          // prices of includes extras do not impact the vehicle price.
          if (Object.hasOwnProperty.call(extra, 'includedBy') && extra.includedBy) {
            calculatePriceForCurrentExtra = false;
          }
      
          if (calculatePriceForCurrentExtra) {
            result.net = result.net + extra.price_net;
            result.gross = result.gross + extra.price_gross;        
          }
        });
      
        return result;
      },
      calculateDiscountedPrice(carConfiguration) {
        var result = {};
      
        var totalPrice = this.calculateTotalPrice(carConfiguration);
      
        ['net', 'gross'].forEach(function(pricing) {
          if (carConfiguration.conditions != null || carConfiguration.coupon) {
            var tax = pricing === 'net' ? 1.19 : 1;
      
            var discount = 0;
            var reduction = 0;
      
            if(carConfiguration.conditions && carConfiguration.conditions.discount) {
              discount = carConfiguration.conditions.discount;
            }
      
            if(carConfiguration.conditions && carConfiguration.conditions.reduction) {
              reduction = carConfiguration.conditions.reduction;
            }
      
            var additionalDiscount = carConfiguration.coupon ? carConfiguration.coupon.discount : 0;
            var additionalReduction = carConfiguration.coupon ? carConfiguration.coupon.reduction : 0;
      
            var savings = totalPrice[pricing] * ((discount+additionalDiscount) || 0) / 100 + ((reduction+additionalReduction) || 0) / tax;
            var intermediatePrice = totalPrice[pricing] - savings;
            var finalPrice = intermediatePrice + (carConfiguration.conditions.provision_costs || 0) / tax;
      
            result[pricing] = {
              savings: savings,
              intermediatePrice: intermediatePrice,
              finalPrice: finalPrice
            };
          }
        });

        return result;
    }
}

function createCarConfigurationObject(engine, trimLevel, bodyDesign, model, extras, conditions) {
    return {
        engine: engine,
        trimLevel: trimLevel,
        bodyDesign: bodyDesign,
        model: model,
        conditions: conditions,
        extras: extras,      
    }
}

export {PriceCalculation, createCarConfigurationObject};